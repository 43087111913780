import React, { Fragment } from "react";
import Navbar from "components/header/navbar";
import Sidebar from "components/sidebar/sidebar";
import SubNavbarWrapper from "components/header/subNavbarWrapper";
import Footer from "components/footers/footer";
import ContentWrapper from "components/body/contentWrapper";
import SubNavbar from "./cards/subNavbar";
import Content from "./cards/content";
// import { useDispatch, useSelector } from "react-redux";
// import { checkError } from "redux/GeneralReducers/GeneralActions";
// import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import { useInitId } from "custom-hook/getData";

const CreateFormSuratMasuk01 = () => {
  // const dispatch = useDispatch();
  // const { BuatSuratReducers } = useSelector((state) => state);
  useInitId()

  return (
    <Fragment>
      <div className="background-dark"></div>
      <Navbar />
      <Sidebar />
      <SubNavbarWrapper>
        <SubNavbar />
      </SubNavbarWrapper>
      <ContentWrapper>
        <Content />
      </ContentWrapper>
      <Footer />
    </Fragment>
  );
}

export default CreateFormSuratMasuk01 
