import { getRequest } from "config/axios";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { getCount } from "redux/Notifications/NotificationActions";
import { store } from "redux/rootRedux/Store";
import { SET_LIST, SET_DEFAULT } from "redux/rootRedux/TypeActions";
import { set_option_default } from "redux/SelectReducers/SelectAction";

export const set_list = (data) => ({
  type: SET_LIST,
  payload: data,
});

export const set_default = () => {
  return (dispatch) => dispatch({ type: SET_DEFAULT });
};

const filterDouble = (e) => {
  return e.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
};

const getId = (name, satuan_kerja_id) => {
  const satuan_kerja = store.getState().LoginReducers;
  let id = satuan_kerja.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id;
  if (
    name === "daftarSuratMasukNonSekretariat" ||
    name === "listMasterJabatan" ||
    name === "listMasterUnitKerja" ||
    name === "listMasterPegawai"

  ) {
    return satuan_kerja_id
  } else {
    return id
  }
}

export const getListMethod = (
  link,
  name,
  current_page = 1,
  satuan_kerja_id = 0,
  per_page = 10
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { start_date, end_date, q, jenis_surat, status_surat } = store.getState().ListSuratReducers;
    // const satuan_kerja = store.getState().LoginReducers;
    // let satuan_kerja_id_store = satuan_kerja.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || satuan_kerja_id;
    let satuan_kerja_id_store = getId(name, satuan_kerja_id)
    console.log("what i get", name, satuan_kerja_id, "satua_ kerja id store", satuan_kerja_id_store);
    // if (name === "daftarSuratMasukNonSekretariat" || name === "daftarSuratMasukSekretariat") {
    //   satuan_kerja_id_store = satuan_kerja_id;
    // }
    // else {
    //   satuan_kerja_id_store = satuan_kerja_id || satuan_kerja.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id ;
    // }
    getRequest(
      `egs/${link}?satuan_kerja_id=${satuan_kerja_id_store}&start_date=${start_date}&end_date=${end_date}&q=${q}&page=${current_page}&per_page=${per_page}&jenis_surat=${jenis_surat}&status_surat=${status_surat}`
    )
      .then((res) => {
        dispatch(setLoading(false));
        const resultFilter = filterDouble(res.data.items);
        dispatch(
          set_list({ name, value: { ...res.data, items: resultFilter } })
        );
        dispatch(set_option_default());
        dispatch(getCount());
      })
      .catch((err) => {
        dispatch(checkError(err))
        console.log("error get list", err)
      });
  };
};

// export const getDevListMethod = (
//   link,
//   name,
//   current_page = 1,
//   satuan_kerja_id = null,
// ) => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     const { start_date, end_date, q, jenis_surat, status_surat } = store.getState().ListSuratReducers;
//     const satuan_kerja = store.getState().LoginReducers;
//     let satuan_kerja_id_store = satuan_kerja_id;
//     if (name === "daftarSuratMasukNonSekretariat" || name === "daftarSuratMasukSekretariat") {
//       satuan_kerja_id_store = satuan_kerja_id;
//     } else {
//       satuan_kerja_id_store = satuan_kerja.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || satuan_kerja_id;
//     }
//     getDevRequest(
//       `egs/${link}?satuan_kerja_id=${satuan_kerja_id_store}&start_date=${start_date}&end_date=${end_date}&q=${q}&page=${current_page}&per_page=10&jenis_surat=${jenis_surat}&status_surat=${status_surat}`
//     )
//       .then((res) => {
//         dispatch(setLoading(false));
//         const resultFilter = filterDouble(res.data.items);
//         dispatch(
//           set_list({ name, value: { ...res.data, items: resultFilter } })
//         );
//         dispatch(set_option_default());
//         dispatch(getCount());
//       })
//       .catch((err) => {
//         // dispatch(checkError(err))
//         console.log("error get list", err)
//       });
//   };
// };

export const getListOfPrintedView = (
  link,
  name,
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { start_date, end_date, jenis_surat, status_surat } = store.getState().ListSuratReducers;
    let req = `egs/master/${link}/export?start_date=${start_date}&end_date=${end_date}`;
    if (name === "listBankSuratKeluarPrinted") {
      req = `egs/master/${link}/export?start_date=${start_date}&end_date=${end_date}&jenis_surat=${jenis_surat}&status_surat=${status_surat}`
    }
    console.log("start ate", start_date, end_date)
    getRequest(req)
      .then((res) => {
        dispatch(setLoading(false));
        const resultFilter = filterDouble(res.data.items.datas);
        dispatch(
          set_list({ name, value: { ...res.data.items, datas: resultFilter } })
        );
      })
      .catch((err) => {
        // dispatch(checkError(err))
        console.log("error get list", err)
      });
  };
};

export const exportExcel = (
  link,
  name,
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const { start_date, end_date, jenis_surat, status_surat } = store.getState().ListSuratReducers;
    let req = `egs/master/${link}/download/excel?start_date=${start_date}&end_date=${end_date}`;
    if (name === "listBankSuratKeluarPrinted") {
      req = `egs/master/${link}/download/excel?start_date=${start_date}&end_date=${end_date}&jenis_surat=${jenis_surat}&status_surat=${status_surat}`
    }
    getRequest(req)
      .then((res) => {
        dispatch(setLoading(false));
        // const resultFilter = filterDouble(res.data.items.datas);
        // dispatch(
        //   set_list({ name, value: { ...res.data.items, datas: resultFilter } })
        // );
      })
      .catch((err) => {
        // dispatch(checkError(err))
        dispatch(setLoading(false));
        console.log("error get list", err)
      });
  };
};

export const exportArsip = (
  link,
  jenis_arsip,
  name,
  is_deleted
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    let req = `egs/${link}?jenis_arsip=${jenis_arsip}&is_deleted=${is_deleted}`;
    getRequest(req)
      .then((res) => {
        dispatch(setLoading(false));
        // const resultFilter = filterDouble(res.data.items.datas);
        dispatch(
          set_list({ name, value: { ...res.data } })
        );
        if (link === "arsip/export/excel") {
          // console.log("respon download", res.data)
          // const link = document.createElement('a');
          // link.setAttribute("target", "_blank");
          // link.download = `${new Date()}`;
          // link.href = res.data.items.url;
          // link.click();
          window.open(res.data.items.url, "_blank").focus();
        }
      })
      .catch((err) => {
        // dispatch(checkError(err))
        dispatch(setLoading(false));
        console.log("error get list", err)
      });
  };
};
