import React from 'react';
import { useSelector } from 'react-redux';

const ContentToViewArsip = () => {
  const { ListSuratReducers } = useSelector(
    (state) => state
  );
  const data = ListSuratReducers.listArsipPrintView;
  console.log("listBankSuratMasukSekretariatPrinted", ListSuratReducers.listArsipPrintView)
  return (
    <div className="view-print__content">
      <div className="content-wrapper">
        <div className='content-body'>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginBottom: "20px" }}>
            <div>
              <br />{data?.title}
              <br /> {data?.tanggal}
              <br /> Badan Kepegawaian dan Pengembangan Sumber Daya Manusia Daerah
            </div>
            <div>
              <br /> {data?.pencetak}
              <br /> {data?.dicetak}
            </div>
          </div>
          <table className='table table-striped table-list'>
            <thead>
              <tr>
                <td width='130px'>Jenis Arsip</td>
                <td>Nama Penyimpanan</td>
                <td>Nomor Dokumen</td>
                <td>Tahun</td>
                <td>Nama Arsip</td>
              </tr>
            </thead>
            <tbody>
              {data.items &&
                data.items.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.jenis_arsip.nama}</td>
                      <td>{item.penyimpanan?.kode}</td>
                      <td>{item.no_dokumen}</td>
                      <td>
                        {item.tahun}
                      </td>
                      <td>
                        {item.nama_arsip}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContentToViewArsip;
