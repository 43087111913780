import React, { useState, Fragment, useEffect } from "react";
import { DataSurat, DataSuratSekretariat } from "./cards/dataSurat";
import Referensi from "./cards/referensi";
import RiwayatCatatan from "./cards/riwayatCatatan";

import { Tabs, Tab } from "react-bootstrap";
import TampilanSurat from "./cards/tampilanSurat";
import { useDispatch, useSelector } from "react-redux";
import {
  checkError,
  setLoading,
  set_status,
} from "redux/GeneralReducers/GeneralActions";
import { getRequest } from "config/axios";
import { withRouter } from "react-router-dom";
import LampiranSurat from "./cards/lampiran";
import DisposisiAtauTeruskan from "./cards/disposisi";
import RiwayatTindakan from "./cards/riwayatTindakan";
// import RiwayatCetak from "./cards/riwayatCetak";
import { getCount, resetNotif } from "redux/Notifications/NotificationActions";
import InfoJadwal from "./cards/infoJadwal";
import { set_surat_masuk } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";
import { DataPengirim, DataPengirimSekretariat } from "./cards/dataPengirim";

export const ContentNonSekretariat = withRouter((props) => {
  const [key, setKey] = useState("tampilan-surat");
  const [details, setDetail] = useState(null);
  const { GeneralReducers } = useSelector((state) => state);
  const { id, kategori } = props.match.params;
  const dispatch = useDispatch();

  const getDetails = () => {
    dispatch(setLoading(true));
    dispatch(
      set_status({
        status: null,
        msg: null,
      })
    );
    getRequest(`egs/mail/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(getCount());
        setDetail(res.data.items);
      })
      .catch((err) => {
        dispatch(checkError(err, "detail-error"));
      });
  };

  useEffect(getDetails, [kategori]);
  // console.log("detail surat non sekretariat_____", details)
  if (GeneralReducers.status === "detail-error") {
    return <Fragment>{GeneralReducers.msg}</Fragment>;
  } else {
    return (
      <Fragment>
        {GeneralReducers.loading && (
          <div className="loading-submit">
            <i className="fa fa-spinner fa-pulse fa-fw fa-2x"></i>
          </div>
        )}
        {GeneralReducers.status === "success printed" && (
          <div className="alert-success alert">{GeneralReducers.msg}</div>
        )}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          className="tabs-header"
          // tabs-link
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="tampilan-surat"
            title="Tampilan Surat"
            className="tabs-link3"
          >
            {details && <TampilanSurat details={details} />}
          </Tab>
          <Tab eventKey="data-surat" title="Data Surat" className="tabs-link2">
            <DataSurat details={details} />
          </Tab>
          <Tab
            // disabled={true}
            eventKey="lampiran"
            title="Lampiran"
            className="tabs-link3"
          >
            <LampiranSurat details={details} />
          </Tab>
          {details && !details.is_teruskan && (
            <Tab
              eventKey="disposisi"
              title="Disposisi/Teruskan"
              className="tabs-link4"
            >
              <DisposisiAtauTeruskan details={details} />
            </Tab>
          )}
          {/* <Tab
            eventKey="riwayat-cetak"
            title="Riwayat Cetak"
            className="tabs-link"
          >
            <RiwayatCetak />
          </Tab> */}
          <Tab
            eventKey="riwayat-tindakan"
            title="Riwayat Tindakan"
            className="tabs-link"
          >
            <RiwayatTindakan />
          </Tab>
          <Tab eventKey="reminder" title="Info Jadwal" className="tabs-link">
            {details && (
              <InfoJadwal data={details} data_jadwal={details.info_jadwal} />
            )}
          </Tab>
          <Tab
            eventKey="data-pengirim"
            title="Data Pengirim"
            className="tabs-link"
          >
            <DataPengirim details={details} />
          </Tab>
        </Tabs>
      </Fragment>
    );
  }
});

export const ContentSekretariat = withRouter((props) => {
  const [key, setKey] = useState("data-surat");
  const [details, setDetail] = useState(null);
  const dispatch = useDispatch();
  const { GeneralReducers, LoginReducers: { userInfo } } = useSelector((state) => state);
  const { id, kategori, tipe } = props.match.params;

  const getDetails = () => {
    dispatch(setLoading(true));
    dispatch(
      set_status({
        status: null,
        msg: null,
      })
    );
    getRequest(`egs/mail-sekretariat/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(getCount());
        setDetail(res.data.items);
        dispatch(resetNotif(tipe));
        dispatch(set_surat_masuk(res.data.items))
        // console.log("detail surat sekretariat", res.data.items, kategori)
      })
      .catch((err) => {
        dispatch(checkError(err, "detail-error"));
      });
  };

  // console.log("detail sekretariat", details)
  useEffect(getDetails, [kategori]);

  if (GeneralReducers.status === "detail-error") {
    return <Fragment>{GeneralReducers.msg}</Fragment>;
  } else {
    return (
      <Fragment>
        {GeneralReducers.loading && (
          <div className="loading-submit">
            <i className="fa fa-spinner fa-pulse fa-fw fa-2x"></i>
          </div>
        )}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          className="tabs-header"
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="data-surat" title="Data Surat" className="tabs-link2">
            {details && <DataSuratSekretariat details={details} />}
          </Tab>

          <Tab eventKey="lampiran" title="Lampiran" className="tabs-link3">
            {details && <LampiranSurat details={details} />}
          </Tab>
          {details && !details.is_teruskan && userInfo.user_level !== 8 && (userInfo.peg_nip !== details.user.peg_nip) && (
            <Tab
              eventKey="disposisi"
              title="Disposisi/Teruskan"
              className="tabs-link4"
            >
              <DisposisiAtauTeruskan details={details} />
            </Tab>
          )}
          <Tab
            eventKey="riwayat-tindakan"
            title="Riwayat Tindakan"
            className="tabs-link"
          >
            <RiwayatTindakan />
          </Tab>
          <Tab eventKey="reminder" title="Info Jadwal" className="tabs-link">
            {details && (
              <InfoJadwal data={details} data_jadwal={details.info_jadwal} />
            )}
          </Tab>
          <Tab
            eventKey="data-pengirim"
            title="Data Pengirim"
            className="tabs-link"
          >
            <DataPengirimSekretariat details={details} />
          </Tab>
        </Tabs>
      </Fragment>
    );
  }
});

export const ContentViewSurat = withRouter((props) => {
  const [key, setKey] = useState("tampilan-surat");
  const [details, setDetail] = useState(null);

  const dispatch = useDispatch();
  const { GeneralReducers, BuatSuratReducers } = useSelector((state) => state);
  const { id } = props.match.params;
  const page = props.match.path.split("/")[2];

  const getDetails = () => {
    dispatch(setLoading(true));
    if (GeneralReducers.status !== "passphrase") {
      dispatch(
        set_status({
          status: null,
          msg: null,
        })
      );
    }
    getRequest(`egs/mail/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(getCount());
        setDetail(res.data.items);
        // console.log("Content view surat", res.data.items)
      })
      .catch((err) => {
        dispatch(checkError(err, "detail-error"));
      });
  };
  useEffect(getDetails, [BuatSuratReducers]);
  if (
    GeneralReducers.status === "detail-error" ||
    GeneralReducers.status === "request-fail"
  ) {
    return <Fragment>{GeneralReducers.msg}</Fragment>;
  } else {
    return (
      <Fragment>
        {GeneralReducers.loading && (
          <div className="loading-submit">
            <i className="fa fa-spinner fa-pulse fa-fw fa-2x"></i>
          </div>
        )}
        {page === "telusuri" && GeneralReducers.status === "passphrase" && (
          <div className="alert alert-success">{GeneralReducers.msg} </div>
        )}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          className="tabs-header"
          // tabs-link
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="tampilan-surat"
            title="Tampilan Surat"
            className="tabs-link3"
          >
            {details && <TampilanSurat keyofTabs={key} details={details} />}
          </Tab>
          <Tab eventKey="data-surat" title="Data Surat" className="tabs-link2">
            <DataSurat getDetails={getDetails} details={details} />
          </Tab>
          <Tab eventKey="referensi" title="Referensi" className="tabs-link">
            <Referensi details={details} />
          </Tab>
          <Tab eventKey="lampiran" title="Lampiran" className="tabs-link3">
            <LampiranSurat details={details} />
          </Tab>
          <Tab
            eventKey="riwayat-catatan"
            title="Riwayat Catatan"
            className="tabs-link"
          >
            {details && <RiwayatCatatan riwayatCatatan={details.history} />}
          </Tab>
        </Tabs>
      </Fragment>
    );
  }
});


export const ContentDetailSurat = withRouter((props) => {
  const [key, setKey] = useState("tampilan-surat");
  const [details, setDetail] = useState(null);
  const { GeneralReducers } = useSelector((state) => state);
  const {
    viewSurat = false,
    dataSurat = false,
    lampiran = false,
    disposisi = false,
    riwayatTindakan = false,
    infoJadwal = false,
  } = props
  const { id, kategori } = props.match.params;
  const dispatch = useDispatch();

  const getDetails = () => {
    dispatch(setLoading(true));
    dispatch(
      set_status({
        status: null,
        msg: null,
      })
    );
    getRequest(`egs/mail/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(getCount());
        setDetail(res.data.items);
      })
      .catch((err) => {
        dispatch(checkError(err, "detail-error"));
      });
  };

  useEffect(getDetails, [kategori]);
  // console.log("detail surat _____", details)
  if (GeneralReducers.status === "detail-error") {
    return <Fragment>{GeneralReducers.msg}</Fragment>;
  } else {
    return (
      <Fragment>
        {GeneralReducers.loading && (
          <div className="loading-submit">
            <i className="fa fa-spinner fa-pulse fa-fw fa-2x"></i>
          </div>
        )}
        {GeneralReducers.status === "success printed" && (
          <div className="alert-success alert">{GeneralReducers.msg}</div>
        )}
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          className="tabs-header"
          // tabs-link
          onSelect={(k) => setKey(k)}
        >
          {viewSurat &&
            <Tab
              eventKey="tampilan-surat"
              title="Tampilan Surat"
              className="tabs-link3"
            >
              {details && <TampilanSurat details={details} />}
            </Tab>
          }
          {dataSurat &&
            <Tab eventKey="data-surat" title="Data Surat" className="tabs-link2">
              <DataSurat details={details} />
            </Tab>
          }
          {lampiran &&
            <Tab
              // disabled={true}
              eventKey="lampiran"
              title="Lampiran"
              className="tabs-link3"
            >
              <LampiranSurat details={details} />
            </Tab>
          }
          {disposisi &&
            details && !details.is_teruskan && (
              <Tab
                eventKey="disposisi"
                title="Disposisi/Teruskan"
                className="tabs-link4"
              >
                <DisposisiAtauTeruskan details={details} />
              </Tab>
            )}
          {/* <Tab
            eventKey="riwayat-cetak"
            title="Riwayat Cetak"
            className="tabs-link"
          >
            <RiwayatCetak />
          </Tab> */}
          {riwayatTindakan &&
            <Tab
              eventKey="riwayat-tindakan"
              title="Riwayat Tindakan"
              className="tabs-link"
            >
              <RiwayatTindakan />
            </Tab>
          }
          {infoJadwal &&
            <Tab eventKey="reminder" title="Info Jadwal" className="tabs-link">
              {details && (
                <InfoJadwal data={details} data_jadwal={details.info_jadwal} />
              )}
            </Tab>
          }
        </Tabs>
      </Fragment>
    );
  }
});
