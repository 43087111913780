import _ from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { set_list } from 'redux/SuratReducers/ListSuratMasuk/ListSuratAction'

function SearchInput({ getList, className = "" }) {
	const [searchKey, setQ] = useState("")
	const dispatch = useDispatch()

	const handleSearch = (q) => {
		dispatch(set_list({ name: "q", value: q }))
		setQ(q)
		getList(1, q)
	}

	const debounceHandle = _.debounce(handleSearch, 1000)

	return (
		<div className={`input-group ${className}`}>
			<div className="input-group-prepend" >
				<span className="input-group-text" style={{ backgroundColor: "transparent", cursor: "pointer" }} id="basic-addon1" >
					<i className="fa fa-search"></i>
				</span>
			</div>
			<input
				type="text"
				className="form-control"
				placeholder="Cari"
				defaultValue={searchKey}
				onChange={(e) => debounceHandle(e.target.value)}
			/>
		</div>
	)
}

export default SearchInput