import React, { Fragment } from "react";

const DataPengirim = (props) => {
  const detail = props.details;
  // const tembusans = detail && [...detail.tembusan.user, ...detail.tembusan.plt];
  // const penerimas = detail && [...detail.penerima.user, ...detail.penerima.plt, ...detail.penerima.user_eksternal];
  return (
    <Fragment>
      <div className="form-group">
        <hr />
        Data Pengirim Surat
      </div>
      <table className="table table-striped font-12">
        <tbody>
          <tr>
            <td>Nama</td>
            <td>: {detail && detail.pengirim.user.data_pegawai.peg_nama}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>: -</td>
          </tr>
          <tr>
            <td>Nomor WhatsApp </td>
            <td>: - </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

const DataPengirimSekretariat = (props) => {
  const detail = props.details;
  return (
    <Fragment>
      <div className="form-group">
        <hr />
      </div>
      <table className="table table-striped font-12">
        <tbody>
          <tr>
            <td>Nama </td>
            <td>: {detail && detail.data_pengirim.nama}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>: {detail && detail.data_pengirim.email}</td>
          </tr>
          <tr>
            <td>Nomor WhatsApp</td>
            <td>: {detail && detail.data_pengirim.nomor_wa}</td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  );
};

export { DataPengirim, DataPengirimSekretariat };
