import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  logout_with_del_token_fcm,
} from "redux/loginRedux/LoginActios";
import "config/notif";
import moment from "moment";
import { getRequest } from "config/axios";
import { checkError } from "redux/GeneralReducers/GeneralActions";
import { getTokenFcm } from "utils/fcm-helper";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const {
    LoginReducers: { userInfo },
    NotificationReducers: { notifCount }
  } = useSelector((state) => state);


  const getNotif = () => {
    getRequest(`egs/notification?page=1&per_page=4`)
      .then((res) => {
        setDataList(res.data);
      }).catch((err) => dispatch(checkError(err, "get notif")));
  };

  useEffect(getTokenFcm, []);
  useEffect(getNotif, [notifCount]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="group-nav">
        <a href="# " className="navbar-brand close-bar">
          <i className="fa fa-bars"></i>
        </a>
        <i className="toggle-sidebar fa fa-chevron-right"></i>
        <div className="sub-nav-brand">EGS GOV - AMS</div>
      </div>

      <div className="group-nav">
        <div className="name-card-nav">
          <div className="name-card-nav-text">
            Selamat Datang,{" "}
            {userInfo && userInfo.user_name}
          </div>
        </div>
        <div className="new-navbar-link">
          <ul className="new-navbar-link-wrapper">
            <li className="new-navbar-list">
              <a
                href="# "
                className="new-navbar-link-item"
                data-dropdown="myNotif"
              >
                <i className="fa fa-bell-o"></i>
                {notifCount > 0 && (
                  <span className="badge-nav">{notifCount}</span>
                )}
              </a>

              <div id="myNotif" className="notif my-dropdown">
                <div className="notif-title">
                  <i className="fa fa-bell"></i> Notifikasi
                </div>
                <ul className="notif-list">
                  {dataList.items ? (
                    dataList.items.map((list, i) => {
                      let url2;
                      if (list.data.kategori === "persetujuan") {
                        url2 = `/surat-keluar/persetujuan`;
                      } else if (list.data.kategori === "konsep") {
                        url2 = `/surat-keluar/konsep`;
                      } else if (list.data.kategori === "surat-masuk") {
                        url2 = `/surat-masuk/daftar-surat`;
                      }
                      return (
                        <li key={i}>
                          <Link to={`${url2}`} className="notif-list-item">
                            <div className="notif-title-item">
                              <b>Surat {list.data.kategori}</b>
                            </div>
                            <div className="notif-content-item">
                              {list.data.perihal}
                            </div>
                            <span className="notif-date-item">
                              <span> {list.data.pengirim}</span>
                              <i>
                                {" "}
                                {moment(list.data.tanggal).format("dddd, ll")}
                              </i>
                            </span>
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <li>
                      <center className="notif-content-item">
                        Anda tidak memiliki notifikasi !
                      </center>
                    </li>
                  )}
                </ul>
                <div className="notif-footer">
                  <Link to="/notifikasi">Lihat Semua</Link>
                </div>
              </div>
            </li>

            <li className="new-navbar-list">
              <a
                href="# "
                className="new-navbar-link-item img-navlink"
                data-dropdown="myProfil"
              >
                <img
                  src="/assets/img/profil.PNG"
                  width="30"
                  height="30"
                  alt=""
                />
              </a>
              <div id="myProfil" className="profil my-dropdown">
                <ul className="profil-list-link">
                  <li>
                    <Link to="/dashboard/personal-info">
                      <i className="fa fa-address-card"></i> Personal Info
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/change-password">
                      <i className="fa fa-lock"></i> Change Password
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="# "
                      onClick={() => dispatch(logout_with_del_token_fcm())}
                    >
                      <i className="fa fa-sign-out"></i> Keluar
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <a
          href="# "
          id="my-card-toggler"
          className="navbar-toggler my-card-toggler"
        >
          <span>
            <i className="my-card-ico fa fa-bars"></i>
          </span>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
