import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";

const { getRequest } = require("config/axios");
const { useState, useEffect, useCallback } = require("react");
const { useDispatch, useSelector } = require("react-redux");
const { checkError } = require("redux/GeneralReducers/GeneralActions");

export const useInitId = () => {
	const dispatch = useDispatch()
	const { BuatSuratReducers } = useSelector((state) => state);
	const [data,] = useState();

	const getReq = useCallback(() => {
		getRequest(`/egs/initId`).then(res => {
			console.log("respon get init ID", BuatSuratReducers.initId);
			// setData(res.data);
			// if (BuatSuratReducers.initId === "") {
			console.log("set value init ID", res);
			dispatch(set_value({ value: res.data.items.initId, name: "initId" }))
			// }
		}).catch(error => dispatch(checkError(error)))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		getReq()

	}, [getReq])

	return data;
}

