import _ from "lodash";
import React, { Fragment, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import Buttons from "components/body/buttons/Button";
import Select from "react-select";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { getListSelect, set_option_default } from "redux/SelectReducers/SelectAction";
import { delRequest, postRequest, putRequest, getRequest } from "config/axios";
import { useCallback } from "react";

const ModalComponent = ({ isOpen, resetValue, jabatan, setJabatan, getList }) => {
  // const [unitKerjaValue, setUnitKerjaValue] = useState({ value: jabatan?.unit_kerja_id, label: jabatan?.unitKerja?.name })
  const dispatch = useDispatch();
  const [jenisJabatan, setJenisJabatan] = useState([])
  const [jabatanForJftOrJfu, setJabatanForJftOrJfu] = useState([]);
  const [idJabatanForJftOrJfu, setIdJabatanForJftOrJfu] = useState([]);
  const [unitKerjaList, setUnitKerjaList] = useState([])
  const [eselonList, setEselonList] = useState([])
  const getListUnitKerja = (id = 0) => {
    dispatch(getListSelect(`pegawai-egs/api/unit-kerja`, "unitKerja", id));
  }
  const postJabatan = () => {
    let newData = { ...jabatan, name: jabatan.isJftOrJfu ? jabatan.name.value : jabatan.name }
    let filter = _.omit(newData, ["SatuanKerja", "UnitKerja", "childs", "eselon", "action", "parentName", "isJftOrJfu", "isTenagaKontrak"]);
    if (jabatan.isJftOrJfu) {
      filter = _.omit(filter, ["eselon_id"]);
    }
    postRequest('egs/pegawai-egs/api/jabatan', filter)
      .then(() => {
        getListUnitKerja()
        onCloseModal()
      }).catch(error => console.log("error post jabatan", error))
  }

  const updateJabatan = () => {
    let newData = { ...jabatan, name: jabatan.isJftOrJfu ? jabatan.name.value : jabatan.name }
    let filter = _.omit(newData, ["SatuanKerja", "UnitKerja", "childs", "eselon", "action", "parentName", "isJftOrJfu", "isTenagaKontrak"]);
    if (jabatan.isJftOrJfu) {
      filter = _.omit(filter, ["eselon_id"]);
    }
    putRequest(`egs/pegawai-egs/api/jabatan/${jabatan.id}`, filter)
      .then(res => {
        getListUnitKerja()
        onCloseModal()
      }).catch(error => console.log("error update jabatan", error))
  }

  const deleteJabatan = () => {
    delRequest(`egs/pegawai-egs/api/jabatan/${jabatan.id}`)
      .then(res => {
        getListUnitKerja()
        onCloseModal()
      }).catch(error => console.log("error delete jabatan", error))
  }

  // const changeUnitKerja = (e) => {
  //   console.log("selec", e.label)
  //   setJabatan({ ...jabatan, unit_kerja_id: e.value })
  //   setUnitKerjaValue({ label: e.label, value: e.value });
  // }

  const onCloseModal = () => {
    resetValue()
    getList();
    dispatch(set_option_default())
    setJabatan({ status: true })
  }

  const getJabatanByJenisJabatan = useCallback(async (id) => {
    setJabatan((jab) => ({ ...jab, name: "" }))
    const req = await getRequest(`egs/pegawai-egs/api/jabatan/get-data/jabatan?jenis_jabatan=${id}`);
    setJabatanForJftOrJfu(req.data.items.map(item => ({ value: item.id, label: item.nama })))
  }, [setJabatan])

  const getListOfModalInput = async (satuan_kerja_id = 7) => {
    const reqJenisJabatan = await getRequest(`egs/pegawai-egs/api/jabatan/get-data/jenis-jabatan`);
    const reqUnitKerja = await getRequest(`egs/pegawai-egs/api/jabatan/get-data/unit-kerja?satuan_kerja_id=${satuan_kerja_id}`);
    const reqEselon = await getRequest(`egs/pegawai-egs/api/jabatan/get-data/eselon`);

    Promise.all([reqJenisJabatan, reqUnitKerja, reqEselon])
      .then(res => {
        // console.log("res", res);
        setJenisJabatan(() => res[0].data.items.map(item => ({ value: item.id, label: item.name })))
        setUnitKerjaList(res[1].data.items.map(item => ({ value: item.unit_kerja_id, label: item.unit_kerja_nama })))
        setEselonList(res[2].data.items.map(item => ({ value: item.eselon_id, label: item.eselon_nm })))
      }).catch(e => console.log("error get list of modals add jabatan", e))
  }

  useEffect(() => {
    if (jabatan.isJftOrJfu) {
      getJabatanByJenisJabatan(idJabatanForJftOrJfu);
    }
  }, [getJabatanByJenisJabatan, idJabatanForJftOrJfu, jabatan.isJftOrJfu]);

  useEffect(() => {
    getListOfModalInput(jabatan.satuan_kerja_id)
  }, [jabatan.satuan_kerja_id]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={isOpen}
        onHide={resetValue}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
            style={{ textTransform: "uppercase" }}
          >
            {jabatan.action === "edit" || jabatan.action === "editChild" ? "Edit" : "Tambah"} Jabatan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="lokasi">Satuan Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih SKPD"
                    className="m-b-5"
                    list="listMasterSatuanKerja"
                    sourceReducer="ListSuratReducers"
                    defaultValue={{
                      value: jabatan?.SatuanKerja?.id,
                      label: jabatan?.SatuanKerja?.name
                    }}
                    onChange={(e) => {
                      getListUnitKerja(e.value);
                      // setUnitKerjaValue(null)
                      setJabatan({ ...jabatan, satuan_kerja_id: e.value })
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jns_jabatan">Jenis Jabatan*</label>
                  <Select
                    placeholder="Pilih Jenis"
                    className="m-b-5"
                    defaultValue={{
                      value: jabatan?.jenis_jabatan,
                      label: jabatan?.jenis_jabatan_nama
                    }}
                    options={jenisJabatan}
                    onChange={(e) => {
                      setIdJabatanForJftOrJfu(e.value);
                      setJabatan({ ...jabatan, jenis_jabatan: e.value, isJftOrJfu: e.label === "JFU" || e.label === "JFT" });
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit_kerja">Unit Kerja*</label>
                  {/* <SelectSatuanKerja
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    list="unitKerja"
                    sourceReducer="ListOptionReducers"
                    value={unitKerjaValue}
                    defaultValue={{ value: jabatan?.unit_kerja_id, label: jabatan?.unitKerja?.name }}
                    onChange={changeUnitKerja}
                  /> */}
                  <Select
                    placeholder="Pilih Unit Kerja"
                    className="m-b-5"
                    defaultValue={{ value: jabatan?.UnitKerja?.id, label: jabatan?.UnitKerja?.name }}
                    options={unitKerjaList}
                    onChange={(e) => setJabatan({ ...jabatan, unit_kerja_id: e.value })}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="lokasi">Jabatan (atasan)*</label>
                  <input
                    type="text"
                    className="form-control"
                    disabled={jabatan.action === "addChild" || jabatan.action === "editChild"}
                    onChange={(e) => setJabatan({ ...jabatan, name: e.target.value })}
                    defaultValue={jabatan.action === "addChild" || jabatan.action === "editChild" ? jabatan.parentName : jabatan.name}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="jabatan">Jabatan*</label>
                  {jabatan.isJftOrJfu ?
                    <Select
                      placeholder="Pilih Jenis"
                      className="m-b-5"
                      defaultValue={jabatan.isJftOrJfu ? {
                        value: jabatan?.name.id,
                        label: jabatan?.name.name
                      } : {
                        value: jabatan?.name,
                        label: jabatan?.jenis_jabatan_nama
                      }}
                      options={jabatanForJftOrJfu}
                      onChange={(e) => {
                        setJabatan({ ...jabatan, name: { ...e } });
                      }}
                    />
                    :
                    <input
                      type="text"
                      className="form-control"
                      disabled={jabatan.action === "add" || jabatan.action === "edit"}
                      onChange={(e) => setJabatan({ ...jabatan, name: e.target.value })}
                      defaultValue={jabatan.action === "editChild" ? jabatan?.name : ""}
                    />
                  }
                </div>

                <div className="form-group">
                  <label htmlFor="kode_jabatan">Kode Jabatan*</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setJabatan({ ...jabatan, code: e.target.value })}
                    defaultValue={jabatan.action === "edit" || jabatan.action === "editChild" ? jabatan.code : ""}
                  />
                </div>
                {!jabatan.isJftOrJfu &&
                  <div className="form-group">
                    <label htmlFor="jns_jabatan">Eselon*</label>
                    <Select
                      placeholder="Pilih Jenis"
                      className="m-b-5"
                      defaultValue={{ value: jabatan?.eselon?.eselon_id, label: jabatan?.eselon?.eselon_nm }}
                      options={eselonList}
                      onChange={(e) => setJabatan({ ...jabatan, eselon_id: e.value })}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              {jabatan.id &&
                <Buttons
                  className="btn btn-danger mr-2"
                  children="Hapus"
                  onClick={deleteJabatan}
                />
              }
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={resetValue}
                className="btn btn-default mr-2"
                children="Batal"
              />
              {jabatan.action === "edit" || jabatan.action === "editChild" ?
                <Buttons
                  onClick={updateJabatan}
                  className="btn btn-primary"
                  children="Ubah"
                // loading={GeneralReducers.loading}
                />
                :
                <Buttons
                  onClick={postJabatan}
                  className="btn btn-primary"
                  children="Simpan"
                // loading={GeneralReducers.loading}
                />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;
