import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import { getRequest } from "config/axios";
import Buttons from "components/body/buttons/Button";

const ModalContent = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers: { loading } } = useSelector((state) => state);
  const { data, setModal, getList } = props;

  const deleteData = async (id) => {
    dispatch(setLoading(true));
    try {
      await getRequest(`/api/egs/list/pegawai/${id}/ganti-status?status=nonaktif`);
      // dispatch(setLoading(false));
      dispatch(
        setStatus({
          status: "success pengguna",
          msg: "Data berhasil dihapus",
        })
      );
      setModal({ modal: false })
      await getList()
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(
        setStatus({
          status: "fail pengguna",
          msg: "Data gagal dihapus",
        })
      );
      setModal({ modal: false })
    }
  }

  return (
    <Fragment>
      <Modal
        show={data.modal}
        onHide={() => setModal({ modal: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Delete Pengguna
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                Apakah anda yakin akan menghapus pengguna ini ?
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <Buttons
                className="btn btn-danger mr-2"
                onClick={() => deleteData(data.id)}
                disabled={loading}
              >
                {loading ?
                  <i className="fa fa-spinner fa-pulse fa-fw cover-empty"></i>
                  :
                  "Hapus"
                }
              </Buttons>
              <Buttons
                className="btn btn-default mr-2"
                onClick={() => setModal({ modal: false })}
                disabled={loading}
              >
                Batal
              </Buttons>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalContent;
