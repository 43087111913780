import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import { postRequest, delRequest, putRequest } from "config/axios";
import Buttons from "components/body/buttons/Button";

const ModalTambahPenyimpanan = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);

  const { data, setModal, getList } = props;
  console.log("penyimpanan", data)
  const createKlasifikasi = () => {
    dispatch(setLoading(true));
    postRequest(`egs/master/arsip/penyimpanan`, { ...data.penyimpanan })
      .then(() => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success penyimpanan",
            msg: "Data berhasil ditambah",
          })
        );
        getList();
        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal ditambah" }));
        }
      });
  };

  const updateKlasifikasi = () => {
    let id = data.penyimpanan.id;
    dispatch(setLoading(true));
    putRequest(`egs/master/arsip/penyimpanan/${id}`, { ...data.penyimpanan })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success penyimpanan",
            msg: "Data berhasil diubah",
          })
        );
        getList();

        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal di ubah" }));
        }
      });
  };

  const deleteKlasifikasi = () => {
    let id = data.penyimpanan.id;
    dispatch(setLoading(true));
    delRequest(`egs/master/arsip/penyimpanan/${id}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success penyimpanan",
            msg: "Data berhasil dihapus",
          })
        );
        getList();

        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal di hapus" }));
        }
      });
  };
  return (
    <Fragment>
      <Modal
        size="lg"
        show={data.modal}
        onHide={() => setModal({ modal: false, klasifikasi: {} })}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {data.action || "Tambah"}{" "}
            {data.penyimpanan
              ? `${data.penyimpanan.nama_parent}`
              : "penyimpanan"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                {GeneralReducers.status === "fail" && (
                  <div className="alert alert-danger">
                    {GeneralReducers.msg}
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="lokasi">Kode*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.penyimpanan && data.penyimpanan.kode}
                    onChange={(e) =>
                      setModal({
                        ...data,
                        penyimpanan: {
                          ...data.penyimpanan,
                          kode: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                {/* <div className="form-group">
                  <label htmlFor="lokasi">Nama*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={data.klasifikasi && data.klasifikasi.nama}
                    onChange={(e) =>
                      setModal({
                        ...data,
                        klasifikasi: {
                          ...data.klasifikasi,
                          nama: e.target.value,
                        },
                      })
                    }
                  />
                </div> */}
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              {GeneralReducers.loading && (
                <i className="fa fa-spinner fa-pulse fa-fw cover-empty mr-2"></i>
              )}
              {data.action === "ubah" && (
                <Buttons
                  onClick={deleteKlasifikasi}
                  className="btn btn-danger pull-left"
                >
                  Hapus
                </Buttons>
              )}
              <Buttons
                className="btn btn-default mr-2"
                onClick={() => setModal({ modal: false, klasifikasi: {} })}
              >
                Batal
              </Buttons>

              <Buttons
                onClick={() => {
                  if (data.action === "Tambah Child") {
                    createKlasifikasi();
                  } else if (data.action === "ubah") {
                    updateKlasifikasi();
                  }
                }}
                className="btn btn-primary mr-2"
                disabled={GeneralReducers.loading}
              >
                Simpan
              </Buttons>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalTambahPenyimpanan;
