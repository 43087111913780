import Loading from "components/body/Loading";
import { getRequest } from "config/axios";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Modal, Tab, Tabs, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value_surat_masuk } from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";

const ModalTo = (props) => {
  const [key, setKey] = useState("jabatan");
  const [q, setQ] = useState("");
  const [listPegawai, setListPegawai] = useState(null);
  const [listJabatan, setListJabatan] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectedPlt, setSelectedPlt] = useState([]);

  const dispatch = useDispatch();
  const {
    GeneralReducers,
    LoginReducers,
    BuatSuratMasukReducers,
  } = useSelector((state) => state);

  const { penerima, tembusan } = BuatSuratMasukReducers;

  const getPegawai = (current_page = 1) => {
    dispatch(setLoading(true));
    let satuan_kerja_id = LoginReducers.userInfo || 0;
    getRequest(
      `api/egs/list/pegawai?satuan_kerja_id=${satuan_kerja_id.data_pegawai.satuan_kerja.satuan_kerja_id}&q=${q}&page=${current_page}&per_page=10`
    )
      .then((res) => {
        // console.log("list pegawai", res);
        dispatch(setLoading(false));
        setListPegawai(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const getJabatan = (current_page = 1) => {
    dispatch(setLoading(true));
    getRequest(
      `api/egs/list/pegawai-by-jabatan?q=${q}&page=${current_page}&per_page=10`
    )
      .then((res) => {
        // console.log("list jabatan", res);
        dispatch(setLoading(false));
        setListJabatan(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const addSelected = (params) => {
    console.log("oke", params);
    const newSelect = {
      data_pegawai: {
        peg_nama: params.fullName,
        peg_nip: params.peg_nip,
        jabatan_nama: params.namaJabatan,
        is_plt: params.is_plt || false,
      },
    };
    if (params.is_plt) {
      const data = selectedPlt.filter(
        (list) => list.data_pegawai.peg_nip !== params.peg_nip
      );
      setSelectedPlt([...data, newSelect]);
    } else {
      const data = selected.filter(
        (list) => list.data_pegawai.peg_nip !== params.peg_nip
      );
      setSelected([...data, newSelect]);
    }
  };

  const delSelected = ({ data_pegawai: { peg_nip, is_plt } }) => {
    if (is_plt) {
      setSelectedPlt(
        selectedPlt.filter((list) => list.data_pegawai.peg_nip !== peg_nip)
      );
    } else {
      setSelected(
        selected.filter((list) => list.data_pegawai.peg_nip !== peg_nip)
      );
    }
  };

  const save = () => {
    if (props.lgShow.title === "KEPADA") {
      dispatch(
        set_value_surat_masuk({
          value: {
            ...penerima,
            plt: selectedPlt,
            user: selected,
          },
          name: "penerima",
        })
      );
    } else {
      dispatch(
        set_value_surat_masuk({
          value: { ...tembusan, plt: selectedPlt, user: selected },
          name: "tembusan",
        })
      );
    }
    setQ("");
    props.setLgShow({ modal: !props.lgShow.modal });
  };

  const setStateToDefault = () => {
    if (props.lgShow.title === "KEPADA") {
      setSelected(penerima.user);
      setSelectedPlt(penerima.plt);
    } else {
      setSelected(tembusan.user);
      setSelectedPlt(tembusan.plt);
    }
  };

  useEffect(setStateToDefault, [props.lgShow.title]);
  useEffect(getPegawai, [q]);
  useEffect(getJabatan, [q]);

  return (
    <Fragment>
      <Modal
        size="lg"
        show={props.lgShow.modal}
        onHide={() => props.setLgShow({ modal: !props.lgShow.modal })}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {props.lgShow.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-9 content-modal">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                className="tabs-header-modal"
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  eventKey="jabatan"
                  title="Jabatan"
                  className="tabs-item-modal"
                >
                  <div className="title-tab-second bg-white no-border-bottom text-right">
                    {/* <span className="mr-3">1 dari 10</span>
                    <i className="fa fa-chevron-left mr-3"></i>
                    <i className="fa fa-chevron-right"></i> */}
                    <span className="mr-3">
                      page {listJabatan && listJabatan.current_page} dari{" "}
                      {listJabatan && listJabatan.last_page}
                    </span>
                    <i
                      className="fa fa-chevron-left mr-3 pointer-mouse"
                      onClick={() => {
                        listJabatan.current_page > 1 &&
                          getJabatan(listJabatan.current_page - 1);
                      }}
                    ></i>
                    <i
                      className="fa fa-chevron-right pointer-mouse"
                      onClick={() => {
                        listJabatan.current_page < listJabatan.last_page &&
                          getJabatan(listJabatan.current_page + 1);
                      }}
                    ></i>
                  </div>
                  <Loading isLoading={GeneralReducers.loading} />
                  <Table striped bordered hover className="table-modal-content">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Jabatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listJabatan &&
                        listJabatan.data.map((list, i) => {
                          return (
                            <tr
                              key={i}
                              className="pointer-mouse"
                              onClick={() => addSelected(list)}
                            >
                              <td>{list.fullName}</td>
                              <td>{list.namaJabatan}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="pegawai" title="Pegawai">
                  <div className="title-tab-second bg-white no-border-bottom text-right">
                    {/* <span className="mr-3">1 dari 10</span>
                    <i className="fa fa-chevron-left mr-3"></i>
                    <i className="fa fa-chevron-right"></i> */}
                    <span className="mr-3">
                      page {listPegawai && listPegawai.current_page} dari{" "}
                      {listPegawai && listPegawai.last_page}
                    </span>
                    <i
                      className="fa fa-chevron-left mr-3 pointer-mouse"
                      onClick={() => {
                        listPegawai.current_page > 1 &&
                          getPegawai(listPegawai.current_page - 1);
                      }}
                    ></i>
                    <i
                      className="fa fa-chevron-right pointer-mouse"
                      onClick={() => {
                        listPegawai.current_page < listPegawai.last_page &&
                          getPegawai(listPegawai.current_page + 1);
                      }}
                    ></i>
                  </div>
                  <Loading isLoading={GeneralReducers.loading} />
                  <Table striped bordered hover className="table-modal-content">
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Unit</th>
                        <th>Jabatan</th>
                        <th>Nip</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listPegawai &&
                        listPegawai.data.map((list, i) => {
                          return (
                            <tr
                              key={i}
                              className="pointer-mouse"
                              onClick={() => addSelected(list)}
                            >
                              <td>{list.fullName}</td>
                              <td>{list.unitKerja}</td>
                              <td>
                                {list.is_plt && "PLT - "} {list.namaJabatan}
                              </td>
                              <td>{list.peg_nip}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </div>
            <div className="col-md-3 content-modal">
              <div className="title-tab-second">Pilihan</div>
              {selected.map((list, i) => {
                return (
                  <ul key={i} className="parent-list">
                    <li className="child-list">
                      {list.data_pegawai.peg_nama}{" "}
                      <i
                        className="fa fa-trash pointer-mouse"
                        onClick={() => delSelected(list)}
                      ></i>
                    </li>
                  </ul>
                );
              })}
              {selectedPlt.length > 0 && (
                <div className="title-tab-second">PLT : </div>
              )}
              {selectedPlt.map((list, i) => {
                return (
                  <ul key={i} className="parent-list">
                    <li className="child-list">
                      {list.data_pegawai.peg_nama}{" "}
                      <i
                        className="fa fa-trash pointer-mouse"
                        onClick={() => delSelected(list)}
                      ></i>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-4 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Kata Kunci"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-md-5 mb-3"></div>
            <div className="col-md-3 tabs-header-modal space-table mb-3">
              <button className="btn btn-primary mr-2" onClick={save}>
                Pilih
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setSelected([]);
                  setSelectedPlt([]);
                }}
              >
                Hapus Semua
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalTo;
