import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ContentToViewKeluar = () => {
  const { ListSuratReducers } = useSelector(
    (state) => state
  );

  const items = ListSuratReducers.listBankSuratKeluarPrinted;
  console.log("listBankSuratKeluarPrinted", ListSuratReducers.listBankSuratKeluarPrinted)

  return (
    <div className="view-print__content">
      <div className="content-wrapper">
        <div className='content-body'>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginBottom: "20px" }}>
            <div>
              <br />{items?.title}
              <br /> {items?.tanggal}
              <br /> Badan Kepegawaian dan Pengembangan Sumber Daya Manusia Daerah
            </div>
            <div>
              <br /> {items?.pencetak}
              <br /> {items?.dicetak}
            </div>
          </div>
          <table className='table table-striped table-list'>
            <thead>
              <tr>
                <td width='130px'>Tanggal Di Buat</td>
                <td>Nomor Surat</td>
                <td>Hal</td>
                <td>Pengirim</td>
                <td>Status</td>
                <td>Jenis Surat</td>
              </tr>
            </thead>
            <tbody>
              {items.datas &&
                items.datas.map((list, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {moment(list.tanggal).format('LL')}
                      </td>
                      <td>{list.no_surat}</td>
                      <td>{list.hal}</td>
                      <td>
                        <b>{list.pengirim}</b>
                      </td>
                      <td>
                        <span className='badge badge-primary'>
                          {list.status}
                        </span>
                      </td>
                      <td>{list.status_mail}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ContentToViewKeluar);
