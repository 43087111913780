import React, { Fragment } from "react";
import Paginate from "components/footers/paginate";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import { set_status } from "redux/GeneralReducers/GeneralActions";
// import { getRequest } from "config/axios";
import ModalContent from "./Modal";
import { useState } from "react";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const history = useHistory();
  const [dataModal, setModal] = useState({})
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listTelusuri;

  const getList = (current_page = 1) => {
    dispatch(getListMethod(`telusuri/mail`, "listTelusuri", current_page));
  };

  // const deleteData = (id) => {
  //   getRequest(`egs/mail/${id}/delete`)
  //     .then((res) => {
  //       console.log("response of delete data", res)
  //     }).catch(e => console.log("error response of delete data", e))
  // }

  const editList = (id) => {
    dispatch(set_status({ status: null, msg: null }));
    history.push(`/surat-keluar/telusuri/detail/${id}`);
  };

  // useEffect(getList, []);

  return (
    <Fragment>
      <ModalContent data={dataModal} setModal={setModal} getList={getList} />
      <div className="content-title">
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Hal</td>
              <td>Tanggal</td>
              <td>Pemeriksa</td>
              <td>Status</td>
              <td>Aksi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map(({ id, hal, pemeriksa, status, created_at }, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`# `} onClick={() => editList(id)}>
                        {hal}
                      </Link>
                    </td>
                    <td>{moment(created_at).format("LL")}</td>
                    <td>{pemeriksa}</td>
                    <td>
                      <span
                        className={`badge ${status === "pemeriksa"
                          ? "badge-primary"
                          : status === "draft"
                            ? "badge-info"
                            : "badge-success"
                          }`}
                      >
                        {status}
                      </span>
                    </td>
                    <td>
                      <span style={{ cursor: "pointer" }} className="fa fa-trash" onClick={() => setModal((e) => ({ ...e, modal: true, id: id }))}></span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
