import React from "react";

import Content from "./cards/content";
import Layout from 'layout';
// import ContentWrapper from "components/body/contentWrapper";
// import Footer from "components/footers/footer";
// import Navbar from "components/header/navbar";
// import Sidebar from "components/sidebar/sidebar";
// import SubNavbar from "./cards/subNavbar";
// import SubNavbarWrapper from "components/header/subNavbarWrapper";

const MasterPegawaiKontrakPage = () => {
  return (
    // <Fragment>
    //   <div className="background-dark"></div>
    //   <Navbar />
    //   <Sidebar />
    //   <SubNavbarWrapper>
    //     <SubNavbar />
    //   </SubNavbarWrapper>
    //   <ContentWrapper>
    //     <Content />
    //   </ContentWrapper>
    //   <Footer />
    // </Fragment>
    <Layout subnavbar={{ type: "standart_header", title: "Master Pegawai Kontrak" }}>
      <Content />
    </Layout>
  );
}

export { MasterPegawaiKontrakPage };
