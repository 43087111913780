import React from 'react';
import Select from 'react-select';
import getListFromRedux from 'utils/list-surat-helper'

function Selects(props) {
	const { ...reducer } = getListFromRedux()
	const { items } = reducer[props.sourceReducer][props.list];
	// console.log("reducers", items)
	const data = items && items.map(item => {
		return { value: item.id, label: item.name }
	})
	return (
		<Select options={data}  {...props} />
	)
}

export default Selects
