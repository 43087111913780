import { useEffect, useState } from 'react';

import { createWorker } from 'tesseract.js';

// eslint-disable-next-line no-useless-escape
const PATTERN_NO_SURAT = /\Nom?o?r? ([a-z0-9-\/\.: ])*/i;
// eslint-disable-next-line no-useless-escape
const PATTERN_HAL = /\Hal [a-z0-9-\/\. \n]+/gi


const useNoSurat = (image) => {
  const [noSurat, setNoSurat] = useState("loading");
  const [hal, setHal] = useState("loading");

  const worker = createWorker({
    logger: m => console.log(m)
  });
  const doOCR = async () => {
    await worker.load();
    await worker.loadLanguage('eng');
    await worker.initialize('eng');
    // const data = await worker.recognize(image);
    const { data: { text } } = await worker.recognize(image);
    // console.log(text)
    const noSurat = text.match(PATTERN_NO_SURAT);
    const hal = text.match(PATTERN_HAL);
    setHal(hal)
    setNoSurat(noSurat);
  };

  useEffect(() => {
    if (image) doOCR();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);
  return [noSurat, hal]
}

export default useNoSurat;
