import React, { Fragment, useState, useEffect } from "react";
import Paginate from "components/footers/paginate";
import ModalContent from "./Modal";
import { Link } from 'react-router-dom';
import { getRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { Button } from "react-bootstrap";
import Loading from "components/body/Loading";
import { exportArsip } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";

const Content = () => {
  const [modal, setModal] = useState({ modal: false, penyimpanan: {} });
  const dispatch = useDispatch();
  let autoNumberArsip = 0;
  let autoNumberArsipTerhapus = 0;
  const { GeneralReducers } = useSelector((state) => state);
  const [dataTabelArsip, setDataTabelArsip] = useState(null);
  const [jenis_arsip, setJenisArsip] = useState("");
  const [optionSelect, setOptions] = useState([]);
  const [tableView, setTableView] = useState("arsip");
  const [dataTabelArsipDeleted, setDataTabelArsipDeleted] = useState(null);
  const { loading, status, msg } = GeneralReducers;

  const getList = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`egs/arsip?page=${current_page}&per_page=5&q=${q}&jenis_arsip=${jenis_arsip}`)
      .then((res) => {
        dispatch(setLoading(false));
        setDataTabelArsip(res.data);
        exportData("pdf", "listArsipPrintView")
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else if (err.message === "Network Error") {
          dispatch(setStatus({ status: "401", msg: err.message }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Gagal mengambil data" }));
        }
      });
  };

  const getListArsipTerhapus = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`egs/arsip/deleted?page=${current_page}&per_page=5&q=${q}&jenis_arsip=${jenis_arsip}`)
      .then((res) => {
        dispatch(setLoading(false));
        setDataTabelArsipDeleted(res.data);
        exportData("pdf", "listArsipDeletedPrintView", true);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else if (err.message === "Network Error") {
          dispatch(setStatus({ status: "401", msg: err.message }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Gagal mengambil data" }));
        }
      });
  };

  const getOptions = () => {
    dispatch(setLoading(true));
    getRequest(`egs/master/arsip/jenis-arsip/pluck`)
      .then((res) => {
        dispatch(setLoading(false));
        console.log("get option", res.data)
        setOptions(res.data.items);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else if (err.message === "Network Error") {
          dispatch(setStatus({ status: "401", msg: err.message }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Gagal mengambil data" }));
        }
      });
  };

  const exportData = (type = "pdf", jenisArsipRed, is_delete = false) => {
    dispatch(exportArsip(`arsip/export/${type}`, jenis_arsip, jenisArsipRed, is_delete));
  }

  const toggleItem = async (id) => {
    dispatch(setLoading(true));
    try {
      await getRequest(`egs/arsip/${id}/toggle`);
      getList();
      getListArsipTerhapus();
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setStatus({ status: "fail", msg: "Request Gagal" }));
    }
  }

  useEffect(getList, [jenis_arsip]);
  useEffect(getOptions, []);
  useEffect(getListArsipTerhapus, [jenis_arsip]);

  return (
    <Fragment>
      <ModalContent
        data={modal}
        getList={getList}
        getListArsipTerhapus={getListArsipTerhapus}
        setModal={setModal}
      />
      <div className="content-title">
        <div>
          <a
            onClick={() =>
              setModal({
                modal: !modal.modal,
                action: "Tambah",
              })
            }
            href="# "
            className="btn btn-sm btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Arsip
          </a>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <input
            placeholder="Search"
            type="text"
            className="form-control"
            onChange={(e) => {
              getList(1, e.target.value)
            }}
          />
          <select
            className="form-control"
            // defaultChecked={status_surat}
            onChange={(e) => {
              setJenisArsip(e.target.value)
            }}
          >
            <option value="">Pilih Status</option>
            {optionSelect.map((opt, i) => {
              return (
                <option key={i} value={opt._id}>{opt.nama}</option>
              )
            })}
          </select>
          <div>
            <button style={{ backgroundColor: "transparent", borderColor: "#eaeaea" }} className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              Export
            </button>
            <div className="dropdown-menu">
              <Link rel="noopener noreferrer" className="dropdown-item" to="# " onClick={() => {
                tableView === "arsip" ?
                  exportData("excel", "listArsipPrintView") : exportData("excel", "listArsipDeletedPrintView", true)
              }}><i className="fa fa-file-excel-o" ></i> Excel</Link>
              <Link className="dropdown-item" to={tableView === "arsip" ? { pathname: "/arsip/list-surat/listArsipPrintView" } : { pathname: "/arsip/list-surat/listArsipDeletedPrintView" }}><i className="fa fa-file-pdf-o"></i> PDF</Link>
            </div>
          </div>
        </div>

      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        {status === "success table arsip" && (
          <>
            <div className="alert alert-success">
              {msg}
              <a
                href="# "
                className="pull-right"
                onClick={() => dispatch(setStatus({ status: null, msg: null }))}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        )}

        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs active"
              id="data-arsip-tab"
              data-toggle="pill"
              href="#data-arsip"
              role="tab"
              aria-controls="data-arsip"
              aria-selected="true"
              onClick={() => {
                setTableView("arsip");
              }}
            >
              Data Arsip
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a
              className="nav-link my-btn-tabs"
              id="data-arsip-terhapus-tab"
              data-toggle="pill"
              href="#data-arsip-terhapus"
              role="tab"
              aria-controls="data-arsip-terhapus"
              aria-selected="true"
              onClick={() => {
                setTableView("arsip deleted");
              }}
            >
              Data Arsip Terhapus
            </a>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div className="mt-5"></div>
          <div
            className="tab-pane fade show active"
            id="data-arsip"
            role="tabpanel"
            aria-labelledby="data-arsip-tab"
          >
            <table className="table table-striped table-list">
              <thead>
                <tr>
                  <td>No</td>
                  <td>Jenis Arsip</td>
                  <td>Nama penyimpanan</td>
                  <td>Nomor Dokument</td>
                  <td>Tahun</td>
                  <td>Nama Arsip</td>
                  <td>Kunci</td>
                  <td>Lihat</td>
                  <td>Unduh</td>
                  <td>Edit</td>
                  <td>Hapus</td>
                </tr>
              </thead>
              <tbody>
                {dataTabelArsip && dataTabelArsip.items.map((item, i) => {
                  autoNumberArsip += 1;
                  return (
                    <tr key={i}>
                      <td>{autoNumberArsip}</td>
                      <td>{item.jenis_arsip.nama}</td>
                      <td>{item.penyimpanan?.kode}</td>
                      <td>{item.no_dokumen}</td>
                      <td>
                        {item.tahun}
                      </td>
                      <td>
                        {item.nama_arsip}
                      </td>
                      <td>
                        <Button size="sm" variant="default" onClick={() => toggleItem(item._id)}>
                          {item.is_locked ?
                            <i style={{ color: "red", cursor: "pointer" }} className="fa fa-lock"></i>
                            : <i style={{ cursor: "pointer" }} className="fa fa-unlock"></i>
                          }
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="default" disabled={item.is_locked} onClick={() => {
                          if (!item.is_locked) {
                            setModal({
                              modal: true,
                              action: "Lihat",
                              tabelArsip: item,
                            })
                          }
                        }}>
                          <i className="fa fa-print"></i>
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="default" disabled={item.is_locked} onClick={() => {
                          if (!item.is_locked) {
                            const link = document.createElement('a');
                            link.setAttribute("target", "_blank");
                            link.download = `${item.no_dokumen}`;
                            link.href = item.file;
                            link.click()
                          }
                        }}>
                          <i className="fa fa-download"></i>
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="default" disabled={item.is_locked} onClick={() => {
                          if (!item.is_locked) {
                            setModal({
                              modal: true,
                              action: "Ubah",
                              tabelArsip: item,
                            })
                          }
                        }}>
                          <i className="fa fa-pencil"></i>
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="default" disabled={item.is_locked} onClick={() => {
                          if (!item.is_locked) {
                            setModal({
                              modal: true,
                              action: "Delete",
                              tabelArsip: item,
                            })
                          }
                        }}>
                          <i className="fa fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {dataTabelArsip && (
              <Paginate
                meta={dataTabelArsip.meta}
                increment={() =>
                  increment(dataTabelArsip.meta.current_page, getList)
                }
                decrement={() =>
                  decrement(dataTabelArsip.meta.current_page, getList)
                }
                setLastPage={() =>
                  setLastPage(dataTabelArsip.meta.last_page, getList)
                }
                setFirstPage={() => setFirstPage(dataTabelArsip.meta.from, getList)}
              />
            )}
          </div>
          <div
            className="tab-pane fade show "
            id="data-arsip-terhapus"
            role="tabpanel"
            aria-labelledby="data-arsip-terhapus-tab"
          >
            <table className="table table-striped table-list">
              <thead>
                <tr>
                  <td>No</td>
                  <td>Jenis Arsip</td>
                  <td>Nama penyimpanan</td>
                  <td>Nomor Dokument</td>
                  <td>Tahun</td>
                  <td>Nama Arsip</td>
                  <td>Restore</td>
                  <td>Hapus Permanent</td>
                </tr>
              </thead>
              <tbody>
                {dataTabelArsipDeleted && dataTabelArsipDeleted.items.map((item, i) => {
                  autoNumberArsipTerhapus += 1;
                  return (
                    <tr key={i}>
                      <td>{autoNumberArsipTerhapus}</td>
                      <td>{item.jenis_arsip.nama}</td>
                      <td>{item.penyimpanan?.kode}</td>
                      <td>{item.no_dokumen}</td>
                      <td>
                        {item.tahun}
                      </td>
                      <td>
                        {item.nama_arsip}
                      </td>
                      <td>
                        <Button size="sm" variant="default" onClick={() => {
                          setModal({
                            modal: true,
                            action: "Restore",
                            tabelArsip: item,
                          })
                        }}>
                          <i className="fa fa-history"></i>
                        </Button>
                      </td>
                      <td>
                        <Button size="sm" variant="default" onClick={() => {
                          setModal({
                            modal: true,
                            action: "Delete Permanent",
                            tabelArsip: item,
                          })
                        }}>
                          <i className="fa fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {dataTabelArsipDeleted && (
              <Paginate
                meta={dataTabelArsipDeleted.meta}
                increment={() =>
                  increment(dataTabelArsipDeleted.meta.current_page, getList)
                }
                decrement={() =>
                  decrement(dataTabelArsipDeleted.meta.current_page, getList)
                }
                setLastPage={() =>
                  setLastPage(dataTabelArsipDeleted.meta.last_page, getList)
                }
                setFirstPage={() => setFirstPage(dataTabelArsipDeleted.meta.from, getList)}
              />
            )}
          </div>
        </div>


        {/* END SKPD */}
      </div>

    </Fragment>
  );
};

export default Content;

