import React, { Fragment, useState, useEffect } from "react";
import Paginate from "components/footers/paginate";
import ModalTambahKlasifikasi from "./ModalTambahKlasifikasi";
import { getRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import SearchInput from "components/body/inputs/SearchInput";

const Content = () => {
  const [modal, setModal] = useState({ modal: false, klasifikasi: {} });
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  const [dataKlasifikasi, setDataKlasifikasi] = useState(null);
  const { loading, status, msg } = GeneralReducers;

  const getList = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`egs/master/klasifikasi?page=${current_page}&q=${q}&per_page=5`)
      .then((res) => {
        dispatch(setLoading(false));
        setDataKlasifikasi(res.data);
        console.log("objecres datat", res.data)
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else if (err.message === "Network Error") {
          dispatch(setStatus({ status: "401", msg: err.message }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Gagal mengambil data" }));
        }
      });
  };

  useEffect(getList, []);

  return (
    <Fragment>
      <ModalTambahKlasifikasi
        data={modal}
        getList={getList}
        setModal={setModal}
      />
      <div className="content-title">
        <div className="content-filter">
          Satuan Organisasi :
          <select name="satuan_organisasi" id="satuan_organisasi">
            <option value="semua">Semua</option>
            <option value="satu">Satu Dua Tiga</option>
          </select>
        </div>
        <SearchInput className="mr-3" getList={getList} />
        <div>
          <a
            onClick={() =>
              setModal({
                modal: !modal.modal,
                action: "Tambah Child",
              })
            }
            href="# "
            className="btn btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Klasifikasi
          </a>
        </div>
      </div>
      <div className="content-body">
        {status === "success klasifikasi" && (
          <>
            <div className="alert alert-success">
              {msg}
              <a
                href="# "
                className="pull-right"
                onClick={() => dispatch(setStatus({ status: null, msg: null }))}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        )}
        {loading && (
          <center colSpan="2">
            <i className="fa fa-spinner fa-pulse fa-fw"></i>
          </center>
        )}
        {/* SKPD */}
        <div
          className="list-parent-child"
          style={{ padding: "0 50px", fontWeight: "bold" }}
        >
          <div>Nama</div>
          <div>Aksi</div>
        </div>
        <div style={{ paddingRight: "20px" }}>
          {!loading &&
            dataKlasifikasi &&
            dataKlasifikasi.items.map((view, i) => {
              return (
                <List
                  data={view}
                  setModal={setModal}
                  key={i}
                  i={i}
                  childs={view.childs}
                />
              );
            })}
        </div>

        {/* END SKPD */}
      </div>
      {dataKlasifikasi && (
        <Paginate
          meta={dataKlasifikasi.meta}
          increment={() =>
            increment(dataKlasifikasi.meta.current_page, getList)
          }
          decrement={() =>
            decrement(dataKlasifikasi.meta.current_page, getList)
          }
          setLastPage={() =>
            setLastPage(dataKlasifikasi.meta.last_page, getList)
          }
          setFirstPage={() => setFirstPage(dataKlasifikasi.meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;

const List = ({ data, childs, i, setModal }) => {
  let child = null;

  if (childs) {
    child = childs.map((view, i) => (
      <List
        data={view}
        key={i}
        i={i}
        setModal={setModal}
        childs={view.childs}
      />
    ));
  }
  return (
    <ul style={{ listStyle: "none" }}>
      <li>
        <div className="nested-list">
          <div>
            <i className="fa fa-chevron-right"></i> {data.kode} {data.nama}
          </div>
          <div>
            <a
              href="# "
              onClick={() =>
                setModal({
                  modal: true,
                  action: "Tambah Child",
                  klasifikasi: {
                    parent: data._id,
                    nama_parent: data.nama,
                  },
                })
              }
              className="btn btn-sm btn-primary mr-1"
            >
              <i className="fa fa-plus "></i>
            </a>
            <a
              href="# "
              onClick={() =>
                setModal({
                  modal: true,
                  action: "ubah",
                  klasifikasi: {
                    id: data._id,
                    nama: data.nama,
                    kode: data.kode,
                  },
                })
              }
              className="btn btn-sm btn-info mr-1"
            >
              <i className="fa fa-edit "></i>
            </a>
          </div>
        </div>
        {child && child}
      </li>
    </ul>
  );
};
