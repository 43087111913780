import moment from 'moment';
// import { useEffect } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

const ContentToViewMasuk = () => {
  const { ListSuratReducers } = useSelector(
    (state) => state
  );
  const items = ListSuratReducers.listBankSuratMasukPrinted;
  console.log("listBankSuratMasukPrinted", ListSuratReducers.listBankSuratMasukPrinted)
  // let satuan_kerja_id =
  //   LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  // const getList = (current_page = 1) => {
  //   dispatch(
  //     getListMethod(
  //       `master/bank-mail-masuk-internal`,
  //       'listBankSuratMasuk',
  //       current_page,
  //       satuan_kerja_id
  //     )
  //   );
  // };

  // useEffect(getList, []);

  return (
    <div className="view-print__content">
      <div className="content-wrapper">
        <div className='content-body'>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", marginBottom: "20px" }}>
            <div>
              <br />{items?.title}
              <br /> {items?.tanggal}
              <br /> Badan Kepegawaian dan Pengembangan Sumber Daya Manusia Daerah
            </div>
            <div>
              <br /> {items?.pencetak}
              <br /> {items?.dicetak}
            </div>
          </div>
          <table className='table table-striped table-list'>
            <thead>
              <tr>
                <td width='130px'>Tanggal Terima</td>
                <td>Nomor Surat</td>
                <td>Hal</td>
                <td>Pengirim</td>
                <td>Disposisi</td>
              </tr>
            </thead>
            <tbody>
              {items.datas &&
                items.datas.map((list, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {moment(list.tanggal).format('LL')}
                      </td>
                      <td>{list.no_surat}</td>
                      <td>{list.hal}</td>
                      <td>
                        <b>{list.pengirim}</b>
                      </td>
                      <td>
                        {list.disposisi
                        }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContentToViewMasuk;
