import React, { Fragment, useState } from "react";
import { checkError, setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { useDispatch, useSelector } from "react-redux";

import Loading from "components/body/Loading";
import ModalHakAkses from "./ModalHakAkses";
import Paginate from "components/footers/paginate";
import Select from "react-select";
import { getRequest } from "config/axios";
import { useEffect } from "react";
import ConfirmModal from "./ConfirmResetPassword";
import ModalContent from './Modal';
import SearchInput from "components/body/inputs/SearchInput";

const Content = () => {
  const [modal, setModal] = useState(false);
  const [dataDelModal, setDelModal] = useState({})
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [dataResetPassword, setDataResetPassword] = useState(false);
  const [list, setList] = useState(null);
  const [dataSkpd, setDataSkpd] = useState(null);
  const [isResetSuccess, setResetSuccess] = useState("idle");
  const [propsModal, setPropsModal] = useState(null)
  const dispatch = useDispatch();
  const { GeneralReducers, LoginReducers } = useSelector((state) => state);
  let satuan_kerja_id =
    LoginReducers.userInfo.data_pegawai.satuan_kerja.satuan_kerja_id || 0;
  const [idSkpd, setIdSkpd] = useState(satuan_kerja_id);

  const getSkpd = () => {
    dispatch(setLoading(true));
    getRequest(`/api/master/skpd`)
      .then((res) => {
        dispatch(setLoading(false));
        filterSkpd(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const getList = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(
      `api/egs/list/pegawai?satuan_kerja_id=${idSkpd}&q=${q}&page=${current_page}&per_page=10`
    )
      .then((res) => {
        dispatch(setLoading(false));
        setList(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const filterSkpd = (data) => {
    let a = data.data.map((list) => {
      return { label: list.satuan_kerja_nama, value: list.satuan_kerja_id };
    });
    setDataSkpd(a);
  };

  const onSelectSkpd = (e) => {
    setIdSkpd(e.value);
  };

  useEffect(getList, [idSkpd]);
  useEffect(getSkpd, []);

  const openModalHakAkses = (data) => {
    setModal(!modal)
    setPropsModal({ nama: data.peg_nama, jabatan: data.namaJabatan, nip: data.peg_nip })
  }
  const confirmResetPassword = (nip) => {
    setDataResetPassword(nip);
    setConfirmModal(!isConfirmModal)
  }
  return (
    <Fragment>
      <ModalContent data={dataDelModal} setModal={setDelModal} getList={getList} />
      {modal &&
        <ModalHakAkses modal={modal} setModal={setModal} propsModal={propsModal} />
      }
      <ConfirmModal modal={isConfirmModal} setModal={setConfirmModal} setResetSuccess={setResetSuccess} dataResetPassword={dataResetPassword} setDataResetPassword={setDataResetPassword} />
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <Select
            placeholder="Pilih SKPD"
            className="m-l-5 m-b-5"
            options={dataSkpd}
            onChange={(e) => onSelectSkpd(e)}
          />
        </div>
        <div className="col-md-4">
          <SearchInput getList={getList} />
        </div>
      </div>
      <hr />
      <div className="content-body">
        {isResetSuccess !== "idle" &&
          <div className={`alert alert-${isResetSuccess}`}>
            {isResetSuccess === "success" ? "Berhasil !" : "Gagal !"}
            <a
              href="# "
              className="pull-right"
              onClick={() => setResetSuccess("idle")}
            >
              <i className="fa fa-close"></i>
            </a>
          </div>
        }
        {GeneralReducers.status === "success pengguna" && (
          <>
            <div className="alert alert-success">
              {GeneralReducers.msg}
              <a
                href="# "
                className="pull-right"
                onClick={() => dispatch(setStatus({ status: null, msg: null }))}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        )}
        {GeneralReducers.status === "fail pengguna" && (
          <>
            <div className="alert alert-danger">
              {GeneralReducers.msg}
              <a
                href="# "
                className="pull-right"
                onClick={() => dispatch(setStatus({ status: null, msg: null }))}
              >
                <i className="fa fa-close"></i>
              </a>
            </div>
          </>
        )}
        <Loading isLoading={GeneralReducers.loading} />
        <table className="table table-striped">
          <thead>
            <tr>
              <td>NIP</td>
              <td>Nama Lengkap</td>
              <td>Jabatan</td>
              <td>Status</td>
              <td>Hak Akses</td>
              <td>Reset Password</td>
              <td>Aksi</td>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.data.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>{list.peg_nip}</td>
                    <td>{list.peg_nama}</td>
                    <td>{list.namaJabatan}</td>
                    <td>{list.peg_status ? "Aktif" : "Non-Aktif"}</td>
                    <td>
                      <a href="# " onClick={() => openModalHakAkses(list)}>
                        <i className="fa fa-edit"></i>
                      </a>
                    </td>
                    <td>
                      <a href="# " onClick={() => confirmResetPassword(list.peg_nip)}>
                        <i className="fa fa-history"></i>
                      </a>
                    </td>
                    <td>
                      <span style={{ cursor: "pointer" }} className="fa fa-trash" onClick={() => setDelModal((e) => ({ ...e, modal: true, id: list.peg_nip }))}></span>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
      {list && (
        <Paginate
          increment={() => increment(list.current_page, getList)}
          meta={list}
          decrement={() => decrement(list.current_page, getList)}
          setLastPage={() => setLastPage(list.last_page_url, getList)}
          setFirstPage={() => setFirstPage(list.first_page_url, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
