import React, { useState } from "react";
import Layout from "layout";
import { postRequest } from "config/axios";

export function ChangePasswordPage() {
  const [isReNewShow, setReNewShow] = useState(false);
  const [data, setData] = useState({});
  const [isNewShow, setNewShow] = useState(false);
  const [status, setStatus] = useState("idle")
  const [msg, setMsg] = useState("")
  const [isLoading, setLoading] = useState(false)

  const onSuccess = () => {
    setData({});
    setLoading(false);
    setStatus("success");
    setMsg("Change Password Success !")
  }

  const onErrors = (params) => {
    const data = params.response.data;
    setLoading(false);
    setStatus("danger");
    setMsg(typeof data.diagnostics?.message === "string" ? data.diagnostics?.message : data.diagnostics?.message?.new_password[0]);
  }

  const onInit = () => {
    setLoading(false);
    setStatus("idle");
    setMsg("")
  }

  const updatePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus("idle");
    postRequest(`egs/pegawai-egs/api/user/change-password`, data)
      .then(e => {
        onSuccess()
      }).catch(onErrors)
  }

  const onShowNewPassword = () => {
    setNewShow(!isNewShow)
  }
  const onShowReNewPassword = () => {
    setReNewShow(!isReNewShow)
  }
  return (
    <Layout subnavbar={{ type: "standart_header", title: "Personal Info" }}>
      <div className="content-title">
        <div>
          Change Password
        </div>
      </div>
      <div className="content-body container-fluid">
        {status !== "idle" &&
          <div className={`alert alert-${status}`}>
            {msg}
            <a
              href="# "
              className="pull-right"
              onClick={() => onInit()}
            >
              <i className="fa fa-close"></i>
            </a>
          </div>
        }
        <form onSubmit={updatePassword}>
          <div className="info-board">
            <div className="row">
              <div className="col-md-12 m-t-20">
                <div className="form-group col-md-6">
                  <label htmlFor="old_password">Password Lama</label>
                  <input
                    type="password"
                    id="old_password"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Password Lama"
                    onChange={(e) => setData({ ...data, old_password: e.target.value })}
                  />
                </div>
                {/* 
                */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group col-md-6">
                  <label htmlFor="re_new_password">Password Baru</label>
                  <div className="input-group mb-3">
                    <input
                      type={isNewShow ? "text" : "password"}
                      className="form-control"
                      placeholder="Password Baru"
                      onChange={(e) => setData({ ...data, new_password: e.target.value })}
                    />
                    <div className="input-group-append" style={{ cursor: "pointer" }}>
                      <span className="input-group-text" id="basic-addon2" onClick={onShowNewPassword}><i className="fa fa-eye"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <div className="form-group col-md-6">
                  <label htmlFor="re_new_password">Ulangi Password Baru</label>
                  <div className="input-group mb-3">
                    <input
                      type={isReNewShow ? "text" : "password"}
                      className="form-control"
                      placeholder="Ulangi Password Baru"
                      onChange={(e) => setData({ ...data, new_password_confirmation: e.target.value })}
                    />
                    <div className="input-group-append" style={{ cursor: "pointer" }}>
                      <span className="input-group-text" id="basic-addon2" onClick={onShowReNewPassword}><i className="fa fa-eye"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <div className="form-group col-md-6">
                  <button type="submit" disabled={isLoading} className="btn btn-primary btn-md">{isLoading ? <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> : "Simpan"}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}
