import "../../styles/content/login.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NavLink, Redirect } from "react-router-dom";
import { getTokenFcm } from "utils/fcm-helper";
import { login } from "redux/loginRedux/LoginActios";
import { setLoading } from "redux/GeneralReducers/GeneralActions";

const Login = () => {
  const [username, setUsername] = useState(null);
  const [typeInput, setTypeInput] = useState("password");
  const [icEyes, setIcEyes] = useState("fa-eye-slash");
  const [password, setPassword] = useState(null);
  const { LoginReducers, GeneralReducers } = useSelector((state) => state);
  const dispatch = useDispatch();

  const postData = (e) => {
    e.preventDefault()
    dispatch(setLoading(true));
    dispatch(login({ username, password }));
  };

  // const openDownload = () => {
  //   window.open("https://api.codemagic.io/artifacts/b8e43696-31a1-4b43-8687-86c68ddc54e5/1ade79e1-a77a-434e-be03-7e41865fb45c/app-debug.apk", "_blank")
  // }
  const toTypePassword = () => {
    setTypeInput("password")
  }
  const toTypeText = () => {
    setTypeInput("text")
  }
  const toEye = () => {
    setIcEyes('fa-eye');
  }
  const toEyeSlash = () => {
    setIcEyes('fa-eye-slash')
  }
  const onShowPassword = () => {
    if (typeInput === 'text') {
      toTypePassword();
      toEyeSlash();
    }
    if (typeInput === 'password') {
      toTypeText();
      toEye();
    }
  }
  useEffect(getTokenFcm, []);

  if (LoginReducers.isLogin) {
    return <Redirect to="dashboard" />;
  }

  return (
    <div className="container-login">
      <div className="content">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + '/assets/img/new-logo.png'} alt="login_logo" />
        </div>
        <ul className="tab-header-login">
          <li>
            <NavLink exact to="/"> Masuk </NavLink>
          </li>
          <li>
            <NavLink exact to="/cari">Cari Surat</NavLink>
          </li>
        </ul>
        <div className="form-wrapper center-element">
          <div className="title">
            <span>Masuk</span>
            <span>Masuk Akun disini</span>{" "}
            {GeneralReducers.status === "error" && (
              <div className="alert alert-danger">{GeneralReducers.msg}</div>
            )}
          </div>
          <form onSubmit={(e) => postData(e)}>
            <div className="input-wrapper">
              <label htmlFor="username">Username</label>
              <i className="fa fa-user-o"></i>
              <input
                type="text"
                autoComplete="off"
                id="username"
                defaultValue={username}
                aria-describedby="emailHelp"
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="password">Password</label>
              <i className="fa fa-lock"></i>
              <input
                type={typeInput}
                id="password"
                aria-describedby="password"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <div onClick={onShowPassword} className="eye-password">
                <i className={`fa ${icEyes} `} />
              </div>
            </div>
            <div className="btn-wrapper">
              <button type="submit" disabled={GeneralReducers.loading}>
                {GeneralReducers.loading ? "Sedang Masuk..." : "Masuk"}
              </button>
            </div>
          </form>
        </div>
        <div className="text-footer">
          <span> &copy; {process.env.REACT_APP_FOOTER_TEXT} </span>
        </div>
      </div>

    </div>
  );
};

export default Login;
