import { getRequest, postRequest } from 'config/axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkError, setLoading, setStatus } from 'redux/GeneralReducers/GeneralActions';

const useMenuList = (callback) => {
	const [menus, setMenus] = useState([])
	const dispatch = useDispatch()

	const post = (data) => {
		dispatch(setLoading(true))
		postRequest(`egs/master/users/menu`, data)
			.then(() => {
				dispatch(setLoading(false))
				callback(false)
				dispatch(setStatus({ status: "success pengguna", msg: "Hak akses berhasil di tambah!" }))
			}).catch(err => dispatch(checkError(err)))
	}

	const get = () => {
		dispatch(setLoading(true))
		getRequest(`egs/master/users/menu`).then(res => {
			setMenus(res.data.items)
			dispatch(setLoading(false))
		}).catch(err => dispatch(checkError(err)))
	}

	useEffect(get, [])

	return { menus, get, post }

}

export default useMenuList