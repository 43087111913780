import React from "react";
import Layout from "layout";
import Content from "./cards/content";
// import SubNavbar from "components/header/subNavbar";

const MasterJabatanPage = () => {
  return (
    <Layout subnavbar={{ type: "standart_header", title: "Master Jabatan" }}>
      <Content />
    </Layout>
  );
}

export { MasterJabatanPage };
