import React, { Fragment, useState, useEffect } from "react";
import Paginate from "components/footers/paginate";
import ModalTambahLokasi from "./ModalTambahLokasi";
import ModalBerkas from "./ModalBerkas";
import { getRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import {
  increment,
  decrement,
  setLastPage,
  setFirstPage,
} from "utils/pagination";
import ModalLihatFoto from "./ModalLihatFoto";
import ModalContent from './Modal';
import _ from "lodash";

const Content = () => {
  const { GeneralReducers } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dataDelModal, setDelModal] = useState({})
  const [id, setId] = useState(null);
  const [idSkpd, setIdSkpd] = useState(null);
  const [searchKey, setQ] = useState("")
  const [modal, setModal] = useState(false);
  const [listData, setListData] = useState(null);
  const [modalBerkas, setModalBerkas] = useState(false);
  const [modalFoto, setModalFoto] = useState(false);
  const [files, setFiles] = useState(null);
  // const [per_page, setPerPage] = useState(10);

  const getList = (current_page = 1, q = "") => {
    dispatch(setLoading(true));
    getRequest(`egs/master/kop-surat?page=${current_page}&q=${q}&per_page=5`)
      .then((res) => {
        setListData(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthenticated" }));
        }
      });
  };

  const handleSearch = (q) => {
    setQ(q)
    getList(1, q)
  }

  const debounceHandle = _.debounce(handleSearch, 1000)

  // const increment = (data) => {
  //   let current_page = data + 1;
  //   setPageList(10);
  //   getList(current_page, per_page);
  // };

  // const decrement = (data) => {
  //   let current_page = data - 1;
  //   getList(current_page, per_page);
  // };

  // const setPageList = (data) => {
  //   setPerPage(data);
  // };

  // const setLastPage = () => {
  //   getList(listData.meta.last_page);
  // };

  // const setFirstPage = () => {
  //   getList(listData.meta.from);
  // };

  useEffect(getList, []);
  useEffect(() => {
    if (!modalFoto) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalFoto]);

  return (
    <Fragment>
      <ModalContent data={dataDelModal} setModal={setDelModal} getList={getList} />
      <ModalTambahLokasi getList={getList} modal={modal} setModal={setModal} />
      <ModalLihatFoto
        modalFoto={modalFoto}
        files={files}
        idSkpd={idSkpd}
        setModalFoto={setModalFoto}
      />
      <ModalBerkas
        modalBerkas={modalBerkas}
        id={id}
        setModalBerkas={setModalBerkas}
        getList={getList}
      />
      <div className="content-title">
        <div className="content-filter">
          Satuan Organisasi :
          <select name="satuan_organisasi" id="satuan_organisasi">
            <option value="semua">Semua</option>
            <option value="satu">Satu Dua Tiga</option>
          </select>
        </div>
        <div className="input-group mr-3">
          <div className="input-group-prepend" >
            <span className="input-group-text" style={{ backgroundColor: "transparent", cursor: "pointer" }} id="basic-addon1" >
              <i className="fa fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Cari"
            defaultValue={searchKey}
            onChange={(e) => debounceHandle(e.target.value)}
          />
        </div>
        <div>
          <a
            onClick={() => setModal(!modal)}
            href="# "
            className="btn btn-primary"
          >
            <i className="fa fa-plus"></i> Tambah Lokasi
          </a>
        </div>
      </div>
      <div className="content-body">
        {GeneralReducers.status === "success kop-surat" && (
          <div className="alert alert-success">
            {GeneralReducers.msg}{" "}
            <a
              href="# "
              className="pull-right"
              onClick={() => dispatch(setStatus({ status: null, msg: null }))}
            >
              <i className="fa fa-close"></i>
            </a>
          </div>
        )}
        <table className="table table-striped">
          <thead>
            <tr>
              <td>Lokasi</td>
              <td>Berkas</td>
              <td>Aksi</td>
            </tr>
          </thead>
          <tbody>
            {GeneralReducers.loading && (
              <tr style={{ textAlign: "center" }}>
                <td colSpan="3">
                  <i className="fa fa-spinner fa-pulse fa-fw"></i>
                </td>
              </tr>
            )}
            {!GeneralReducers.loading &&
              listData &&
              listData.items.map((view, i) => {
                return (
                  <tr key={i}>
                    <td>{view.satuan_kerja_nama}</td>
                    <td>
                      {view.file.length ? (
                        <a
                          onClick={() => {
                            setModalFoto(!modalFoto);
                            setFiles(view.file);
                            setIdSkpd(view._id);
                          }}
                          className="btn btn-sm btn-primary"
                          href="# "
                        >
                          <i className="fa fa-eye"></i> Lihat
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            setModalBerkas(!modalBerkas);
                            setId(view._id);
                          }}
                          href="# "
                          className="btn btn-sm btn-info"
                        >
                          <i className="fa fa-plus"></i>
                          File
                        </a>
                      )}
                    </td>
                    <td>
                      <span style={{ cursor: "pointer" }} className="fa fa-trash" onClick={() => setDelModal((e) => ({ ...e, modal: true, id: view._id }))}></span>
                    </td>
                  </tr>
                );
              })}
            {/* <tr>
              <td>asdf</td>
              <td>
                <a
                  onClick={() => {
                    setModalBerkas(!modalBerkas);
                    setId("123");
                  }}
                  href="# "
                >
                  <i className="fa fa-file"></i>
                </a>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      {listData && (
        <Paginate
          increment={() => increment(listData.meta.current_page, getList)}
          meta={listData.meta}
          decrement={() => decrement(listData.meta.current_page, getList)}
          setLastPage={() => setLastPage(listData.meta.last_page, getList)}
          setFirstPage={() => setFirstPage(listData.meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
