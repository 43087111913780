import React from "react";
import Content from "./cards/content";
import Layout from "layout";

const MasterPegawaiPage = () => {
  return (
    <Layout subnavbar={{ type: "standart_header", title: "Master Pegawai" }}>
      <Content />
    </Layout>
  );
}

export { MasterPegawaiPage };
