import React, { Fragment, useEffect, useState } from "react";
import {
  reset_value_surat_masuk,
  set_value_surat_masuk,
} from "redux/SuratReducers/BuatSuratMasuk/BuatSuratMasukAction";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ImageProccessing from "./ImageProccessing";
import InfoJadwal from "./infoJadwal";
import InputsWithLabel from "components/body/inputs/InputsWithLabel";
import ModalKodeKlasifikasi from "./ModalKodeKlasifikasi";
import ModalTo from "./ModalTo";
import { postFileRequest } from "config/axios";
import { setLoading } from "redux/GeneralReducers/GeneralActions";

const Content = () => {
  const [lgShow, setLgShow] = useState({ modal: false, title: "" });
  const [kodeShow, setKodeShow] = useState(false);
  const [showImageProcessing, setShowImageProcessing] = useState(false);
  const dispatch = useDispatch();
  const { GeneralReducers, BuatSuratMasukReducers } = useSelector(
    (state) => state
  );
  const {
    tahun_agenda,
    tanggal_terima,
    no_surat,
    tanggal_surat,
    dari,
    kepala_surat,
    penerima,
    tembusan,
    lampiran,
    data_pengirim
  } = BuatSuratMasukReducers;
  // const [attacment, setAttacment] = useState([]);
  // console.log("buat surat masuk reducers", BuatSuratMasukReducers);
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const uploadFile = (data) => {
    let a = [];
    for (let index = 0; index < data.length; index++) {
      a = [...a, uploadFileDropped(data[index])];
    }

    Promise.all(a)
      .then((res) => {
        dispatch(
          set_value_surat_masuk({
            value: [...lampiran, ...res],
            name: "lampiran",
          })
        );
        dispatch(setLoading(false));
      })
      .catch((err) => console.log("errrr", err));
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    uploadFile(files);
  };

  const onClickFile = (e) => {
    e.preventDefault();
    const files = e.target.files;
    uploadFile(files);
  };

  const uploadFileDropped = (data) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", data);
    return new Promise((res, rej) => {
      postFileRequest(`api/master/store-file`, formData)
        .then((respon) => {
          return res(respon.data.data);
        })
        .catch((err) => rej(err));
    });
  };
  const delAttacment = (data) => {
    const filtered = lampiran.filter((list) => list.file_name !== data.file_name);
    dispatch(
      set_value_surat_masuk({
        value: [...filtered],
        name: "lampiran",
      })
    );
  };

  const reset = () => {
    dispatch(reset_value_surat_masuk());
  };
  useEffect(reset, []);

  return (
    <Fragment>
      {showImageProcessing && <ImageProccessing show={showImageProcessing} setShow={setShowImageProcessing} />}
      <ModalTo lgShow={lgShow} setLgShow={setLgShow} />
      <ModalKodeKlasifikasi kodeShow={kodeShow} setKodeShow={setKodeShow} />
      <div className="form-group">
        {GeneralReducers.status === "success surat-masuk" ? (
          <div className="alert alert-success">{GeneralReducers.msg}</div>
        ) : (
          <label htmlFor="pemeriksa"> Buat Surat Masuk</label>
        )}
      </div>
      {GeneralReducers.loading && (
        <div className="loading-submit">
          <i className="fa fa-spinner fa-pulse fa-fw fa-2x"></i>
        </div>
      )}
      {GeneralReducers.status === "alert-surat-masuk" && (
        <div className="alert alert-danger">{GeneralReducers.msg}</div>
      )}
      <div className="form-row">
        <Button onClick={() => setShowImageProcessing(true)} className="form-group col-md-12" variant="primary">
          Scan Image
        </Button>

        <div className="form-group col-md-4">
          <InputsWithLabel
            type="number"
            label="Tahun Agenda"
            id="tahun_agenda"
            value={tahun_agenda}
            action={(e) =>
              dispatch(
                set_value_surat_masuk({
                  value: e.target.value,
                  name: "tahun_agenda",
                })
              )}
          />
          {/* <label htmlFor="tahun_agenda">Tahun Agenda</label>
          <input
            type="number"
            className="form-control"
            id="tahun_agenda"
            value={tahun_agenda}
            onChange={
            }
          /> */}
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="tanggal_terima">Tanggal Terima</label>
          <div className="input-date-style">
            <DatePicker
              selected={tanggal_terima}
              placeholderText="Dari"
              className="form-control"
              dateFormat="d MMMM yyyy"
              onChange={(e) =>
                dispatch(
                  set_value_surat_masuk({ value: e, name: "tanggal_terima" })
                )
              }
            />
          </div>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="nomor_surat">Nomor Surat</label>
          <input
            type="text"
            className="form-control"
            id="nomor_surat"
            autoComplete="off"
            value={no_surat}
            onChange={(e) =>
              dispatch(
                set_value_surat_masuk({
                  value: e.target.value,
                  name: "no_surat",
                })
              )
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-5">
          <label htmlFor="tanggal_terima">Tanggal Surat</label>
          <div className="input-date-style">
            <DatePicker
              selected={tanggal_surat}
              placeholderText="Dari"
              className="form-control"
              dateFormat="d MMMM yyyy"
              onChange={(e) =>
                dispatch(
                  set_value_surat_masuk({ value: e, name: "tanggal_surat" })
                )
              }
            />
          </div>
        </div>
        <div className="form-group col-md-7">
          <label htmlFor="dari">Dari</label>
          <input
            type="text"
            className="form-control"
            autoComplete="off"
            id="dari"
            value={dari}
            onChange={(e) =>
              dispatch(
                set_value_surat_masuk({ value: e.target.value, name: "dari" })
              )
            }
          />
        </div>
      </div>
      <div className="form">
        <label htmlFor="nomor_surat col-md-12">Kepada</label>
        <div className="form-inline mb-2">
          <a
            href="# "
            onClick={() => setLgShow({ modal: !lgShow.modal, title: "KEPADA" })}
            className="btn btn-primary col-md-1"
          >
            Tambah
          </a>

          <div className="col-md-11 m-t-5">
            <div className="box-input-with-list font-12">
              <ol style={{ marginTop: "10px" }}>
                {penerima.user &&
                  penerima.user.map((view, i) => {
                    return (
                      <li key={i}>
                        {view.data_pegawai.peg_nama} -{" "}
                        {view.data_pegawai.jabatan_nama}
                      </li>
                    );
                  })}
                {penerima.user.length > 0 && penerima.plt.length > 0 && <hr />}
                {penerima.plt.map((view, i) => {
                  return (
                    <li key={i}>
                      {view.data_pegawai.peg_nama} -{" "}
                      {view.data_pegawai.is_plt && "(PLT) "}
                      {view.data_pegawai.jabatan_nama}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="form">
        <label htmlFor="inputEmail33">Tembusan</label>
        <div className="form-inline mb-2">
          <a
            href="# "
            onClick={() =>
              setLgShow({ modal: !lgShow.modal, title: "TEMBUSAN" })
            }
            className="btn btn-primary col-md-1"
          >
            Tambah
          </a>
          <div className="col-md-11 m-t-5">
            <div className="box-input-with-list font-12">
              <ol style={{ marginTop: "10px" }}>
                {tembusan.user.map((view, i) => {
                  return (
                    <li key={i}>
                      {view.data_pegawai.peg_nama} -{" "}
                      {view.data_pegawai.jabatan_nama}
                    </li>
                  );
                })}
                {tembusan.plt.length > 0 && <hr />}
                {tembusan.plt.map((view, i) => {
                  return (
                    <li key={i}>
                      {view.data_pegawai.peg_nama} -{" "}
                      {view.data_pegawai.is_plt && "(PLT) "}
                      {view.data_pegawai.jabatan_nama}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="hal">Hal</label>
        <input
          type="text"
          autoComplete="off"
          className="form-control"
          value={kepala_surat.perihal}
          onChange={(e) =>
            dispatch(
              set_value_surat_masuk({
                value: { ...kepala_surat, perihal: e.target.value },
                name: "kepala_surat",
              })
            )
          }
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-3">
          <label htmlFor="inputCity">Lampiran</label>
          <input
            type="number"
            className="form-control"
            value={kepala_surat.lampiran.value}
            id="inputCity"
            onChange={(e) =>
              dispatch(
                set_value_surat_masuk({
                  value: {
                    ...kepala_surat,
                    lampiran: {
                      ...kepala_surat.lampiran,
                      value: e.target.value,
                    },
                  },
                  name: "kepala_surat",
                })
              )
            }
          />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="inputState">Pilih</label>
          <select
            id="inputState"
            className="form-control"
            value={kepala_surat.lampiran.type}
            onChange={(e) =>
              dispatch(
                set_value_surat_masuk({
                  value: {
                    ...kepala_surat,
                    lampiran: {
                      ...kepala_surat.lampiran,
                      type: e.target.value,
                    },
                  },
                  name: "kepala_surat",
                })
              )
            }
          >
            <option value="">Pilih</option>
            <option value="Lembar">Lembar</option>
            <option value="Rangkap">Rangkap</option>
          </select>
        </div>
        <div className="form-group col-md-5">
          <label htmlFor="inputZip">
            <a href="# " onClick={() => setKodeShow(!kodeShow)}>
              Kode Klasifikasi
            </a>
          </label>
          <input
            type="text"
            className="form-control"
            id="inputZip"
            disabled={true}
            defaultValue={
              kepala_surat.klasifikasi && kepala_surat.klasifikasi.nama
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="inputState">Sifat (Penyampaian)</label>
          <select
            id="inputState"
            className="form-control"
            value={kepala_surat.sifat.penyampaian}
            onChange={(e) =>
              dispatch(
                set_value_surat_masuk({
                  value: {
                    ...kepala_surat,
                    sifat: {
                      ...kepala_surat.sifat,
                      penyampaian: e.target.value,
                    },
                  },
                  name: "kepala_surat",
                })
              )
            }
          >
            <option value="">Pilih</option>
            <option value="Biasa">Biasa</option>
            <option value="Cepat">Cepat</option>
          </select>
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="inputState">Sifat (Pengamanan)</label>
          <select
            id="inputState"
            className="form-control"
            value={kepala_surat.sifat.pengamanan}
            onChange={(e) =>
              dispatch(
                set_value_surat_masuk({
                  value: {
                    ...kepala_surat,
                    sifat: {
                      ...kepala_surat.sifat,
                      pengamanan: e.target.value,
                    },
                  },
                  name: "kepala_surat",
                })
              )
            }
          >
            <option value="">Pilih</option>
            <option value="Biasa">Biasa</option>
            <option value="Rahasia">Rahasia</option>
          </select>
        </div>
      </div>
      <div className="form-row row mt-4">
        <div className="form-group">
          <label htmlFor="inputState">Arsip*</label>
          <div className="row">
            <div className="col-md-5 mb-2">
              <div className="container-fluid">
                <div className="form-group">
                  {/* {GeneralReducers.status === "fail" && (
                    <div className="alert alert-danger">
                      {GeneralReducers.msg}
                    </div>
                  )} */}

                  <div className="content-lampiran">
                    <div className="container-lampiran">
                      <div
                        className="drop-container-lampiran"
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                      >
                        <input
                          type="file"
                          id="file"
                          onChange={onClickFile}
                          style={{ display: "none" }}
                        />
                        <div className="drop-message-lampiran">
                          <div className="upload-icon-lampiran"></div>
                          Drag & Drop files here or click{" "}
                          <label htmlFor="file" className="pointer-mouse">
                            <b> here </b>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="col-md-12">
                Size Attachment yang dapat diproses adalah maksimal 1 MB
              </div>
              <div className="col-md-12">
                Dokumen Surat / Fax : {"  "}
                {lampiran.map((list, i) => {
                  return (
                    <div key={i} className="font-12">
                      <a
                        href="# "
                        onClick={(e) => {
                          e.preventDefault();
                          delAttacment(list);
                        }}
                      >
                        <i className="fa fa-trash red"></i>
                      </a>{" "}
                      {list.file_name} {" "}
                      ({list.file_size})
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12">
          <InfoJadwal />
        </div>
        <div className="col-md-12">
          <div>Data Pengirim Surat</div>
          <div className="form-group m-t-10">
            <label htmlFor="nama">Nama *</label>
            <input
              type="text"
              className="form-control"
              defaultValue=""
              onChange={(e) =>
                dispatch(
                  set_value_surat_masuk({
                    value: { ...data_pengirim, nama: e.target.value },
                    name: "data_pengirim"
                  })
                )
              }
            />
          </div>
          <div className="form-group m-t-10">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              className="form-control"
              defaultValue=""
              onChange={(e) =>
                dispatch(
                  set_value_surat_masuk({
                    value: { ...data_pengirim, email: e.target.value },
                    name: "data_pengirim"
                  })
                )
              }
            />
          </div>
          <div className="form-group m-t-10">
            <label htmlFor="nomor">Nomor Whatsapp *</label>
            <input
              type="text"
              className="form-control"
              defaultValue=""
              onChange={(e) =>
                dispatch(
                  set_value_surat_masuk({
                    value: { ...data_pengirim, nomor_wa: e.target.value },
                    name: "data_pengirim"
                  })
                )
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Content;
