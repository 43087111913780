import { getRequest } from "config/axios";
import Buttons from "components/body/buttons/Button";
import Modals from "components/modals/Modals";
import React, { useState, useEffect, useRef } from "react";
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import ClassicEditor from "ckeditor5-build-classic-dna";
import { useDispatch, useSelector } from "react-redux";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";

import InputsWithLabel from "components/body/inputs/InputsWithLabel";
import { PDFDocument } from 'pdf-lib'

import { debounce } from "lodash-es";
import { useCallback } from "react";
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = () => {
  const {
    BuatSuratReducers: { initId, isi_surat, tipe_surat, no_surat_req, kepala_surat, attachment, bentuk_surat },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isNumberAvailable, setNumberAvailable] = useState(null)
  const [show, setShow] = useState(false);
  const [templateData, setTemplate] = useState([]);
  const [ttdImages, setTtdImage] = useState();
  const [, setTipeSurat] = useState(null);
  const [idTemplateSurat, setIdTemplateSurat] = useState(0);
  const [radioChosen, setRadioChosen] = useState(null);
  // const [pdfUrl, setPdfUrl] = useState("");
  // const [preview, setPreview] = useState(attachment.originalFile)
  const editorRef = useRef();

  const setVal = () => {
    setTipeSurat(tipe_surat);
  };

  const changetemplate = ({ id, nama, template, type }) => {
    setShow(!show)
    // setTipeSurat(nama);
    setIdTemplateSurat(id);
    dispatch(set_value({ value: type, name: "tipe_surat" }));
    dispatch(set_value({ value: template, name: "isi_surat" }));
  };

  const closeModal = () => {
    setShow(false)
    setRadioChosen(null)
  }

  const toggleModal = (data) => {
    setShow(!show)
    setRadioChosen(data)
  }

  const getTemplate = () => {
    dispatch(setLoading(true));
    getRequest(`egs/mail-type`)
      .then((res) => {
        setTemplate(res.data.items);
      }).catch((err) => dispatch(checkError(err)));
  };

  const validateNoSurat = (q) => {

    dispatch(setLoading(true));
    const kode_klasifikasi = kepala_surat?.klasifikasi?.kode
    getRequest(`egs/check-available-no-surat?nomor=${q}&kd_klasifikasi=${kode_klasifikasi}&tipe_surat=undangan`)
      .then(res => {
        console.log("respon check nomor surat", res);
        setNumberAvailable(res.data.items.available);
        dispatch(setLoading(false));
        // if (res.data.items.available) {

        // } else {
        //   dispatch(set_value({
        //     value: "",
        //     name: "no_surat",
        //   }))
        // }
      })
      .catch(err => dispatch(checkError(err)))
  }

  const save = (event, editor) => {
    // const data = editor.getData();
    // editorRef.getContent({ format: 'text' });
    // (editor.getContent({format: 'text'})
    dispatch(set_value({ value: event, name: "isi_surat" }));
  };

  const checkNoSurat = useCallback(
    debounce((q) => validateNoSurat(q), 1000, [])
  )

  const handleNoSurat = (e) => {
    dispatch(set_value({
      value: e.target.value,
      name: "no_surat_req",
    }))
    checkNoSurat(e.target.value)
  }

  async function createPdf(image, type) {
    let urlImage = image;
    let imageBytes = await fetch(urlImage).then((res) => {
      // console.log("type image", res.type);
      return res.arrayBuffer()
    }).catch(e => console.log("create pdf", e))

    // Fetch PNG image
    // const pngUrl = 'https://pdf-lib.js.org/assets/minions_banana_alpha.png';
    // const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());

    // Create a new PDFDocument
    let preview = attachment.originalFile;
    if (preview instanceof File) {
      // console.log("instance of ")
      preview = URL.createObjectURL(preview);
    }
    // const urlPdf = preview === "" ? attachment.originalFile : preview;
    // const urlPdf = "https://eniaja.pallakastudio.com/egs/s3/generate/12sdafhjhj.pdf";
    // const existingPdfBytes = await fetch("https://pdf-lib.js.org/assets/with_update_sections.pdf").then(res => res.arrayBuffer()).catch(e => console.log("error get pdf", e))
    const existingPdfBytes = await fetch(preview).then(e => e.arrayBuffer())
    console.log("existing", existingPdfBytes);
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    let imageSign;
    let imageSignDim;
    // Embed the JPG image bytes and PNG image bytes
    if (type === 'jpeg') {
      imageSign = await pdfDoc.embedJpg(imageBytes);
      imageSignDim = imageSign.scale(0.7);
    } else {
      imageSign = await pdfDoc.embedPng(imageBytes);
      imageSignDim = imageSign.scale(0.4);
    }

    // Get the width/height of the JPG image scaled down to 25% of its original size
    // const jpgDims = jpgImage.scale(0.25)

    // Get the width/height of the PNG image scaled down to 50% of its original size
    // const pngDims = pngImage.scale(0.5)

    // Add a blank page to the document
    const pages = pdfDoc.getPages()
    // const firstPage = pages[0]
    // const { width, height } = firstPage.getSize()

    // Draw the JPG image in the center of the page

    pages.map(item => {
      let sizeObj = getImageSize(imageSignDim, item)
      return item.drawImage(imageSign, {
        x: sizeObj.x,
        y: sizeObj.y,
        width: sizeObj.width,
        height: sizeObj.height,
      })
    })
    const result = await pdfDoc.saveAsBase64({ dataUri: true });
    // const result2 = await pdfDoc.save();
    // const fetched = await fetch(result).then(e => e.blob())
    // setPdfUrl(result);
    dispatch(set_value({ value: { ...attachment, signedFile: result }, name: "attachment" }))
  }

  const getImageSize = (image, pageSize) => {
    if (image.width > pageSize.getWidth()) {
      return { width: pageSize.getWidth() - 10, height: 80, x: 10, y: 7 }
    } else {
      let imageSize = image.width;
      return { width: image.width, height: image.height, x: (pageSize.getWidth() / 2) - (imageSize / 2), y: 10 }
    }
  }

  const handleChangeJenisSurat = (id) => {
    return templateData.find(e => e._id === id)
  }

  const handlePreviewFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let selectedFile = URL.createObjectURL(e.target.files[0]);
      // setPreview(selectedFile);
      // setPdfUrl(selectedFile)

      // dispatch(set_value({ value: { ...attachment, signedFile: e.target.files[0] }, name: "attachment" }))
      dispatch(set_value({ value: { ...attachment, originalFile: e.target.files[0], signedFile: selectedFile }, name: "attachment" }))
    }
  }

  const changeTypeRadio = (data) => {
    setIdTemplateSurat(0)
    // setPdfUrl("");
    // setPreview("");
    dispatch(set_value({ value: "", name: "isi_surat" }))
    dispatch(set_value({ value: data, name: "bentuk_surat" }))
  }

  const deleteAttachment = () => {
    // setPdfUrl("");
    // setPreview("");
    dispatch(set_value({ value: { originalFile: "", signedFile: "" }, name: "attachment" }))
  }

  const getImageTtd = (id) => {
    getRequest(`/egs/mail/pdf/meta/${id}`)
      .then(e => {
        console.log("meta fail", e);
        setTtdImage(e.data.items.list_ttd)
      }).catch(e => {
        console.log("error", e);
      })
  }

  useEffect(() => {
    if (initId) {
      getImageTtd(initId)
    }
  }, [initId]);

  useEffect(getTemplate, []);
  useEffect(setVal, [isi_surat]);

  return (
    <div
      style={{
        width: "95%",
        marginRight: 0,
      }}
    >
      <div
        className="custom-control custom-radio custom-control-inline"
      >
        <input
          type="radio"
          id="textEditor"
          className="custom-control-input"
          name="bentuk_surat"
          checked={bentuk_surat === 'text-editor'}
          onChange={() => {
            changeTypeRadio("text-editor")
          }}
        />
        <label className="custom-control-label" htmlFor="textEditor">
          Text Editor
        </label>
      </div>
      <div
        className="custom-control custom-radio custom-control-inline"
      >
        <input
          type="radio"
          id="softcopy"
          className="custom-control-input"
          name="bentuk_surat"
          checked={bentuk_surat === 'softcopy'}
          onChange={async () => {
            // toggleModal({ id: items._id, nama: items.nama, template: items.template, type: items.type })

            changeTypeRadio("softcopy")
          }}
        />
        <label className="custom-control-label" htmlFor="softcopy">
          Softcopy
        </label>
      </div>
      <Modals show={{ isShow: show }} setShow={closeModal} title="Konfirmasi" size="md">
        <div className="p-10">
          <p>Isi Surat anda belum tersimpan, Apakah anda Yakin ingin mengubah jenis Surat ? </p>
          <Buttons className="btn btn-default btn-sm m-r-5" onClick={closeModal}>No</Buttons>
          <Buttons className="btn btn-primary btn-sm" onClick={() => changetemplate(radioChosen)}>Yes</Buttons>
        </div>
      </Modals>
      <hr />
      <div >
        <label htmlFor="textEditor" style={{ marginTop: 18 }}>Jenis Surat</label>
        <select
          className="form-control"
          value={idTemplateSurat}
          onChange={(e) => {
            console.log("value of jenis surat", e.target.value)
            const dataSelect = handleChangeJenisSurat(e.target.value);
            bentuk_surat === 'text-editor' ?
              toggleModal({ id: e.target.value, nama: dataSelect.nama, template: dataSelect.template, type: dataSelect.type }) :
              toggleModal({ id: e.target.value, nama: dataSelect.nama, template: "", type: dataSelect.type })
          }}
        >
          <option value="">Pilih</option>
          {templateData.map((items, i) => {
            return (
              <option value={items._id} key={i}>{items.nama}</option>
            );
          })}
        </select>
      </div>
      {/* <PdfViewerComponent document={process.env.PUBLIC_URL + '/assets/samplePdf.pdf'} /> */}
      <div className="form-group m-t-10">
        <InputsWithLabel
          type="number"
          label="Nomor Surat"
          placeholder="Gunakan penomoran secara manual"
          value={no_surat_req}
          action={(e) =>
            handleNoSurat(e)
          }
        />
        {isNumberAvailable === false && <span className="multiple-info" style={{ color: 'red', fontSize: "10px" }}>Nomor surat telah di gunakan </span>}
      </div>
      {bentuk_surat === 'text-editor' ?

        <Editor
          onInit={(evt, editor) => editorRef.current = editor}
          value={isi_surat}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help | table',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
          tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
          onEditorChange={save}
          scriptLoading={{ async: true, delay: 500 }}
        />
        :
        <>
          {attachment.signedFile === "" ?
            <div className="card">
              <h6 className="card-header">Softcopy</h6>
              <div className="card-body text-center">
                <h5 className="card-title">Klik tombol di bawah ini untuk upload dokumen
                </h5>
                <p className="card-text">Dokumen yang diupload berupa .pdf dengan kapasitas  maksimal 100 MB </p>
                <input type="file" onChange={handlePreviewFile} className="btn btn-primary filess" id="filess"></input>
              </div>
            </div>
            :
            <div className="row mt-3">
              <div className="col-12 text-center">
                <div className="alert alert-success">{attachment?.originalFile.name} <span onClick={deleteAttachment} className="fa fa-trash"></span></div>
              </div>
              <div className="col-3">
                <div className="mb-4" style={{ textAlign: "center" }}>
                  <h5 style={{ fontWeight: "bolder" }}>Silakan Pilih Salah Satu</h5>
                  <p>Bentuk Tanda Tangan Digital</p>
                </div>
                {ttdImages && ttdImages.map((item, i) => {
                  return (
                    <label key={i} htmlFor={item.image} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #eaeaea", borderTop: "1px solid #eaeaea", cursor: "pointer", overflow: "hidden" }}>
                      {/* <input type="radio" name="image_ttd" id="image_ttd1" className="mb-3" onChange={() => createPdf("/assets/img/ttd.jpg", "jpeg")} /> */}
                      <input type="radio" name="image_ttd" id={item.image} onChange={() => createPdf(item.image, item.type)} />
                      <img src={item.image} height="100px" width="200px" style={{ objectFit: "contain" }} alt={item.image} />
                    </label>
                  )
                })}
                {/* <label htmlFor="image_ttd2" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #eaeaea", cursor: "pointer" }}> */}
                {/* <input type="radio" name="image_ttd" id="image_ttd2" className="mb-3" onChange={() => createPdf("/assets/img/ttd.png", "png")} /> */}
                {/* <input type="radio" name="image_ttd" id="image_ttd2" className="mb-3" /> */}
                {/* <img src="/assets/img/ttd.png" height="100px" width="200px" style={{ objectFit: "contain" }} alt="image1" /> */}
                {/* </label> */}
              </div>
              <div className="col-9">

                <iframe
                  // ref={refIframe}
                  title="pdf"
                  src={attachment.signedFile}
                  type="application/pdf"
                  style={{ width: "100%", height: "90vh" }}
                >
                </iframe>
              </div>
            </div>
          }
        </>
      }
      <br />
    </div>
  );
};

export default TextEditor;
