import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import Buttons from "components/body/buttons/Button";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import { delRequest, getRequest, postRequest, putRequest } from "config/axios";
import _ from "lodash";
import Select from 'react-select';
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

const ModalComponent = ({ isOpen, resetModal, unitKerja, setUnitKerja, getList }) => {
  const [dataUnitKerjaSetelah, setDataUnitKerjaSetelah] = useState([]);

  const getUnitKerjaSetelah = useCallback((id) => {
    getRequest(`egs/pegawai-egs/api/unit-kerja/${id}/childs`)
      .then(res => {
        // console.log("respon from unit kerja child", res);
        const list = makeListOptionOfSelect(res.data.items)
        setDataUnitKerjaSetelah(list);
      }).catch(e => console.log(e));
  }, [])

  const makeListOptionOfSelect = (data) => {
    return data.map(item => ({ label: item.name, value: item.id }))
  }

  const submit = () => {
    let lastValueOfUnitKerja = _.omit(unitKerja, "action");
    postRequest(`egs/pegawai-egs/api/unit-kerja`, lastValueOfUnitKerja)
      .then(res => {
        resetModal()
        getList()
      }).catch(err => console.log("respon unit kerja error", err))
  };

  const update = (id) => {
    let lastValueOfUnitKerja = _.omit(unitKerja, ["action", "parentName"]);
    putRequest(`egs/pegawai-egs/api/unit-kerja/${id}`, lastValueOfUnitKerja)
      .then(res => {
        resetModal()
        getList()
      }).catch(err => console.log("respon unit kerja error", err))
  }

  const deleteData = (id) => {
    delRequest(`egs/pegawai-egs/api/unit-kerja/${id}`)
      .then(res => {
        resetModal()
        getList()
      }).catch(err => console.log("respon unit kerja error", err))
  }

  useEffect(() => {
    if (unitKerja.unit_kerja_parent) {
      getUnitKerjaSetelah(unitKerja.unit_kerja_parent)
    }
  }, [getUnitKerjaSetelah, unitKerja.unit_kerja_parent])

  return (
    <Fragment>
      <Modal
        size="lg"
        show={isOpen}
        onHide={resetModal}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {unitKerja?.action === "edit" || unitKerja.action === "editChild" ? "Ubah" : "Tambah"} Unit Kerja {unitKerja?.action === "addChild" && "Bawahan"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="form-group">
                  <label htmlFor="lokasi">Satuan Kerja*</label>
                  <SelectSatuanKerja
                    placeholder="Pilih Satuan Kerja"
                    className="m-b-5"
                    list="listMasterSatuanKerja"
                    sourceReducer="ListSuratReducers"
                    defaultValue={{ value: unitKerja?.SatuanKerja?.id, label: unitKerja?.SatuanKerja?.name }}
                    onChange={(e) => setUnitKerja({ ...unitKerja, satuan_kerja_id: e.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="unit-kerja">Unit Kerja*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja?.action === "addChild" || unitKerja?.action === "editChild" ? unitKerja?.parentName : unitKerja.name}
                    disabled={unitKerja?.action === "addChild" || unitKerja.action === "editChild"}
                    onChange={e => setUnitKerja({ ...unitKerja, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lokasi">Unit Kerja (Bawahan)*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja.action === "editChild" ? unitKerja?.name : ""}
                    disabled={!!!unitKerja.unit_kerja_parent}
                    onChange={e => setUnitKerja({ ...unitKerja, name: e.target.value })}
                  />
                </div>
                {(unitKerja.action === 'addChild' || unitKerja.action === 'editChild') &&
                  <div className="form-group">
                    <label htmlFor="lokasi">Posisi Unit Kerja Setelah*</label>
                    <Select
                      className="m-b-5"
                      defaultValue={{ value: unitKerja.posisi_unit_kerja_setelah, label: "Taruh di urutan pertama" }}
                      options={dataUnitKerjaSetelah}
                      onChange={(e) => setUnitKerja({ ...unitKerja, posisi_unit_kerja_setelah: e.value })}
                    />
                  </div>
                }
                <div className="form-group">
                  <label htmlFor="code">Kode Unit Kerja*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={unitKerja?.code}
                    onChange={e => setUnitKerja({ ...unitKerja, code: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-6 mb-3">
              {unitKerja.id &&
                <Buttons
                  className="btn btn-danger mr-2"
                  children="Hapus"
                  onClick={() => deleteData(unitKerja.id)}
                />
              }
            </div>
            <div className="col-md-6 text-right mb-3">
              <Buttons
                onClick={resetModal}
                className="btn btn-default mr-2"
                children="Batal"
              />
              {unitKerja.id ?
                <Buttons
                  onClick={() => update(unitKerja.id)}
                  className="btn btn-primary"
                  children="Ubah"
                // loading={GeneralReducers.loading}
                />
                :
                <Buttons
                  onClick={submit}
                  className="btn btn-primary"
                  children="Simpan"
                // loading={GeneralReducers.loading}
                />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalComponent;