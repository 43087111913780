import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setStatus } from "redux/GeneralReducers/GeneralActions";
import { postRequest, delRequest, putRequest, getRequest } from "config/axios";
import Buttons from "components/body/buttons/Button";
import Select from 'react-select';

const ModalContent = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers } = useSelector((state) => state);
  const { data, setModal, getList, getListArsipTerhapus } = props;
  const [listJenisArsip, setJenisArsip] = useState([])
  const [listNamaPenyimpanan, setNamaPenyimpanan] = useState([])

  const getJenisArsip = (q = "") => {
    return getRequest(`egs/master/arsip/jenis-arsip?q=${q}`);
  }

  const getNamaPenyimpanan = (q = "") => {
    return getRequest(`egs/master/arsip/penyimpanan?q=${q}`);
  }

  const changeStructure = (data) => {
    return data.map(item => ({ value: item._id, label: item.nama }))
  }
  const changeStructureChild = (data) => {
    return data.map(item => ({ value: item._id, label: item.kode }))
  }

  const getListOfJenisArsipAndNamaPenyimpanan = useCallback(() => {
    Promise.all([getJenisArsip(), getNamaPenyimpanan()]).then(res => {
      setJenisArsip(changeStructure(res[0].data.items))
      setNamaPenyimpanan(changeStructureChild(res[1].data.items))
    }).catch(err => {
      dispatch(setLoading(false));
      if (err.request.status === 401) {
        dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
      } else {
        dispatch(setStatus({ status: "fail", msg: "Data gagal di ambil" }));
      }
    })
  }, [dispatch])

  const createTableArsip = () => {
    dispatch(setLoading(true));
    const dataForm = new FormData();
    dataForm.append("jenis_arsip", data.tabelArsip.jenis_arsip._id);
    dataForm.append("penyimpanan", data.tabelArsip.penyimpanan._id);
    dataForm.append("no_dokumen", data.tabelArsip.no_dokumen);
    dataForm.append("tahun", data.tabelArsip.tahun);
    dataForm.append("nama_arsip", data.tabelArsip.nama_arsip);
    dataForm.append("file", data.tabelArsip.file);
    postRequest(`egs/arsip`, dataForm)
      .then(async () => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success table arsip",
            msg: "Data berhasil ditambah",
          })
        );
        await getList();
        await getListArsipTerhapus();
        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal ditambah" }));
        }
      });
  };

  const updateTableArsip = () => {
    let id = data.tabelArsip._id;
    dispatch(setLoading(true));
    const dataForm = new FormData();
    dataForm.append("jenis_arsip", data.tabelArsip.jenis_arsip._id);
    dataForm.append("penyimpanan", data.tabelArsip.penyimpanan._id);
    dataForm.append("no_dokumen", data.tabelArsip.no_dokumen);
    dataForm.append("tahun", data.tabelArsip.tahun);
    dataForm.append("nama_arsip", data.tabelArsip.nama_arsip);
    dataForm.append("file", data.tabelArsip.file);
    putRequest(`egs/arsip/${id}`, dataForm)
      .then(async (res) => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success table arsip",
            msg: "Data berhasil diubah",
          })
        );
        await getList();
        await getListArsipTerhapus();
        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal di ubah" }));
        }
      });
  };

  const restoreArsip = () => {
    let id = data.tabelArsip._id;
    dispatch(setLoading(true));
    getRequest(`egs/arsip/deleted/${id}/restore`)
      .then(async () => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success table arsip",
            msg: "Data berhasil di restore",
          })
        );
        await getList();
        await getListArsipTerhapus();
        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal di hapus" }));
        }
      });
  };

  const deleteJenisArsip = () => {
    let id = data.tabelArsip._id;
    dispatch(setLoading(true));
    delRequest(`egs/arsip/${id}`)
      .then(async () => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success table arsip",
            msg: "Data berhasil dihapus",
          })
        );
        await getList();
        await getListArsipTerhapus();
        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal di hapus" }));
        }
      });
  };

  const deletePermanent = () => {
    let id = data.tabelArsip._id;
    dispatch(setLoading(true));
    delRequest(`egs/arsip/deleted/${id}/destroy`)
      .then(async () => {
        dispatch(setLoading(false));
        dispatch(
          setStatus({
            status: "success table arsip",
            msg: "Data berhasil dihapus",
          })
        );
        await getList();
        await getListArsipTerhapus();
        setModal({ ...data, modal: false });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.request.status === 401) {
          dispatch(setStatus({ status: "401", msg: "Unauthorization" }));
        } else {
          dispatch(setStatus({ status: "fail", msg: "Data gagal di hapus" }));
        }
      });
  };
  useEffect(() => {
    getListOfJenisArsipAndNamaPenyimpanan()
  }, [getListOfJenisArsipAndNamaPenyimpanan])

  return (
    <Fragment>
      <Modal
        size={data.action === "Delete" ? "md" : "lg"}
        show={data.modal}
        onHide={() => setModal({ modal: false })}
        aria-labelledby="example-modal-sizes-title-lg"
        animation={false}
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            {data.action || "Tambah"} {" "}
            Table Arsip
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                {data.action === "Lihat" ?
                  <iframe
                    title="pdf"
                    src={data.tabelArsip.file}
                    type="application/pdf"
                    style={{ width: "100%", height: "90vh" }}
                  >
                  </iframe>
                  :
                  data.action === "Delete" ?
                    <>
                      Apakah anda yakin menghapus arsip ini ?
                    </> :
                    <>
                      {GeneralReducers.status === "fail" && (
                        <div className="alert alert-danger">
                          {GeneralReducers.msg}
                        </div>
                      )}

                      <div className="form-group">
                        <label htmlFor="jenis-arsip">Jenis Arsip</label>
                        <Select
                          options={listJenisArsip}
                          defaultValue={{ value: data.tabelArsip?.jenis_arsip?._id, label: data.tabelArsip?.jenis_arsip?.nama }}
                          onChange={(e) => {
                            setModal({
                              ...data,
                              tabelArsip: {
                                ...data.tabelArsip,
                                jenis_arsip: { _id: e.value },
                              },
                            })
                          }} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="nama-penyimpanan">Nama Penyimpanan</label>
                        <Select
                          options={listNamaPenyimpanan}
                          isDisable={true}
                          defaultValue={{ value: data.tabelArsip?.penyimpanan?._id, label: data.tabelArsip?.penyimpanan?.kode }}
                          onChange={(e) => {
                            setModal({
                              ...data,
                              tabelArsip: {
                                ...data.tabelArsip,
                                penyimpanan: { _id: e.value },
                              },
                            })
                          }} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="no-dokument">Nomor Dokumen</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={data.action === "Delete" || data.action === "Restore" || data.action === "Delete Permanent"}
                          defaultValue={data.tabelArsip && data.tabelArsip.no_dokumen}
                          onChange={(e) =>
                            setModal({
                              ...data,
                              tabelArsip: {
                                ...data.tabelArsip,
                                no_dokumen: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="tahun">Tahun</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={data.action === "Delete" || data.action === "Restore" || data.action === "Delete Permanent"}
                          defaultValue={data.tabelArsip && data.tabelArsip.tahun}
                          onChange={(e) =>
                            setModal({
                              ...data,
                              tabelArsip: {
                                ...data.tabelArsip,
                                tahun: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="nama-arsip">Nama Arsip</label>
                        <input
                          type="text"
                          className="form-control"
                          disabled={data.action === "Delete" || data.action === "Restore" || data.action === "Delete Permanent"}
                          defaultValue={data.tabelArsip && data.tabelArsip.nama_arsip}
                          onChange={(e) =>
                            setModal({
                              ...data,
                              tabelArsip: {
                                ...data.tabelArsip,
                                nama_arsip: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="file">Unggah Arsip</label>
                        <div style={{ border: "1px solid #ced4da", padding: "15px 0", borderRadius: "10px" }}>
                          <input
                            type="file"
                            id="file"
                            disabled={data.action === "Delete" || data.action === "Restore" || data.action === "Delete Permanent"}
                            onChange={(e) =>
                              setModal({
                                ...data,
                                tabelArsip: {
                                  ...data.tabelArsip,
                                  file: e.target.files[0],
                                },
                              })
                            }
                            style={{ display: "none" }}
                          />
                          <div className="drop-message-lampiran">
                            {data.tabelArsip?.file ?
                              <p>{typeof data.tabelArsip.file !== "string" ? data.tabelArsip.file.name : data.tabelArsip.file} </p> :
                              <p>Maksimal kapasitas file arsip yang diunggah sebesar 100 MB</p>
                            }
                            <label htmlFor="file" className="pointer-mouse">
                              <div style={{ padding: "8px 15px", backgroundColor: "#121D50", color: "white" }}><i className="fa fa-upload"></i> Unggah Di sini</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                }
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              {GeneralReducers.loading && (
                <i className="fa fa-spinner fa-pulse fa-fw cover-empty mr-2"></i>
              )}
              {data.action === "Delete" && (
                <Buttons
                  onClick={deleteJenisArsip}
                  className="btn btn-danger pull-left"
                >
                  Hapus
                </Buttons>
              )}
              {data.action === "Delete Permanent" && (
                <Buttons
                  onClick={deletePermanent}
                  className="btn btn-danger pull-left"
                >
                  Hapus Permanent
                </Buttons>
              )}
              {data.action === "Restore" && (
                <Buttons
                  onClick={restoreArsip}
                  className="btn btn-info pull-left"
                >
                  Restore
                </Buttons>
              )}
              <Buttons
                className="btn btn-default mr-2"
                onClick={() => setModal({ modal: false })}
              >
                Batal
              </Buttons>
              {(data.action === "Tambah" || data.action === "Ubah") &&
                <Buttons
                  onClick={() => {
                    if (data.action === "Tambah") {
                      createTableArsip();
                    } else if (data.action === "Ubah") {
                      updateTableArsip();
                    }
                  }}
                  className="btn btn-primary mr-2"
                  disabled={GeneralReducers.loading || data.action === "Delete"}
                >
                  Simpan
                </Buttons>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalContent;
