import { getRequest } from "config/axios";
import React, { Fragment, useState } from "react";

import { Modal } from "react-bootstrap";

const ConfirmModal = (props) => {
  const [isLoading, setLoading] = useState(false)
  const resetPassword = () => {
    setLoading(true)
    getRequest(`egs/pegawai-egs/api/user/reset-password/${props.dataResetPassword}`)
      .then((res) => {
        setLoading(false)
        console.log("respon reset password ", res);
        props.setModal(false);
        props.setDataResetPassword("");
        props.setResetSuccess("success");
      }).catch((error) => {
        setLoading(false);
        console.log("reset password fail", error);
        props.setModal(false);
        props.setDataResetPassword("");
        props.setResetSuccess("danger");
      });
  }
  const onHideModal = () => {
    props.setModal(false);
    props.setDataResetPassword("");
  }
  return (
    <Fragment>
      <Modal
        show={props.modal}
        onHide={onHideModal}
        onShow={() => props.setResetSuccess("idle")}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="header-modal" closeButton>
          <Modal.Title
            className="title-modal"
            id="example-modal-sizes-title-lg"
          >
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row reset-margin-row">
            <div className="col-md-12 content-modal">
              <div className="container-fluid">
                <div className="text-row space-around">
                  <span>Reset Password dengan nip: {props.dataResetPassword} ?</span>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row reset-margin-row ">
            <div className="col-md-12 text-right mb-3">
              <button className="btn btn-default mr-2" onClick={onHideModal}>Batal</button>
              <button className="btn btn-primary mr-2" onClick={resetPassword} disabled={isLoading}>
                {isLoading ?
                  <i className="fa fa-spinner fa-pulse fa-fw"></i>
                  : "Reset"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ConfirmModal;
