/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from "react";

const LampiranSurat = ({ details }) => {
  return (
    <Fragment>
      <hr />
      <div className="wrapper-table">
        <div className="content-body" style={{ backgroundColor: '#fff', color: "#3d3f56", padding: '20px 0' }}>
          <div className="row reset-row">
            <div className="col-md-3">Dokumen Surat / Fax : </div>
            <div className="col-md-9">
              {details?.lampiran.map((list, i) => {
                return (
                  <div key={i}>
                    <a
                      href={list.file_url}
                      download
                      target="_blank"
                    >
                      <span className="badge badge-success">
                        {list.file_name}
                      </span>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LampiranSurat;
