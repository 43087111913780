// import Content from "./cards/content";
import React from "react";
// import Layout from "layout";
// import ContentToPrint from "./cards/contentToPrint";
// import { useState } from "react";

export function ViewPdfTesting() {
  // const [pdfUrl, setPdfUrl] = useState("")
  // const linkPdf = process.env.PUBLIC_URL === "string" ? process.env.PUBLIC_URL + '/assets/samplePdf.pdf' : "";


  // useEffect(() => {
  //   createPdf()
  // }, [pdfUrl])

  return (
    // <Layout subnavbar={{
    //   print_btn: true,
    //   title: "Bank Surat Keluar",
    //   keysTab: "listBankSuratKeluar",
    //   path: "/bank-surat/list-surat/",
    //   ComponentToPrint: ContentToPrint,
    // }}>

    <div>
      <iframe
        title="pdf"
        src="https://docs.google.com/gview?url=https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf&embedded=true"
        type="application/pdf"
        style={{ width: "100%", height: "90vh" }}
      >
      </iframe>
    </div>
    // </Layout>
  );
}
