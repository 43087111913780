import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "components/footers/paginate";
import Loading from "components/body/Loading";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import ModalContent from "./cards/Modal";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listTerkirimExternal;

  const [dataModal, setModal] = useState({})

  const getList = (current_page = 1) => {
    dispatch(
      getListMethod(
        "terkirim/mail-sekretariat",
        "listTerkirimExternal",
        current_page
      )
    );
  };
  // console.log("terikirim sekretariat", items)

  return (
    <Fragment>
      <ModalContent data={dataModal} setModal={setModal} getList={getList} />
      <div className="content-title">
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr style={{ textAlign: "left" }}>
              <td style={{ textAlign: "left" }}>Kepada</td>
              <td style={{ textAlign: "left" }}>No Surat</td>
              <td style={{ textAlign: "left" }}>Resi</td>
              <td style={{ textAlign: "left" }}>Hal</td>
              <td style={{ textAlign: "left" }}>Tanggal</td>
              <td style={{ textAlign: "left" }}>Aksi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i}>
                    <td>{list.kepada}</td>
                    <td>
                      <Link
                        to={`/surat-masuk/terkirim/detail-terkirim-sekretariat/sekretariat/${list.id}`}
                      >
                        {list.no_surat}
                      </Link>
                    </td>
                    <td>{list?.resi}</td>
                    <td>{list.hal}</td>
                    <td>{moment(list.tanggal).format("LL")}</td>
                    <td>
                      <span style={{ cursor: "pointer" }} className="fa fa-trash" onClick={() => setModal((e) => ({ ...e, modal: true, id: list.id }))}></span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default Content;
