import React, { Fragment } from "react";
import Paginate from "components/footers/paginate";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import Loading from "components/body/Loading";
import { useEffect } from "react";
import { useState } from "react";
import { getRequest } from "config/axios";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import Select from "react-select";
import ModalContent from "./Modal";

const DaftarSuratNonSekretariat = () => {
  const dispatch = useDispatch();
  const [dataModal, setModal] = useState({})
  const {
    GeneralReducers: { loading },
    ListSuratReducers: { daftarSuratMasukNonSekretariat: { items, meta } },
    NotificationReducers: { surat_masuk },
  } = useSelector((state) => state);
  // const { items, meta } = ListSuratReducers.daftarSuratMasukNonSekretariat;
  // const { surat_masuk } = NotificationReducers;
  // const { loading } = GeneralReducers;
  const [dataSkpd, setDataSkpd] = useState(null);

  const getList = (current_page = 1) => {
    dispatch(
      getListMethod("mail", "daftarSuratMasukNonSekretariat", current_page)
    );
  };

  const getSkpd = () => {
    getRequest(`/api/master/skpd`)
      .then((res) => {
        dispatch(setLoading(false));
        filterSkpd(res.data);
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const filterSkpd = (data) => {
    let a = data.data.map((list) => {
      return { label: list.satuan_kerja_nama, value: list.satuan_kerja_id };
    });
    setDataSkpd(a);
  };

  const onSelectSkpd = (e) => {
    let current_page = 1;
    dispatch(
      getListMethod(
        "mail",
        "daftarSuratMasukNonSekretariat",
        current_page,
        e.value
      )
    );
  };

  useEffect(getList, [surat_masuk]);
  useEffect(getSkpd, []);

  return (
    <Fragment>
      <ModalContent data={dataModal} setModal={setModal} getList={getList} />
      <div className="content-title">
        <div className="content-filter">
          <label>Show : </label>
          <Select
            placeholder="Pilih SKPD"
            className="select-class"
            options={dataSkpd}
            onChange={(e) => onSelectSkpd(e)}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Nomor Surat</td>
              <td>Dari</td>
              <td>Hal</td>
              <td>Tipe</td>
              <td>Tgl. Surat</td>
              <td>Aksi</td>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((list, i) => {
                return (
                  <tr key={i} className={!list.is_read ? `unread-msg` : ""}>
                    <td>
                      <Link
                        className="link-table"
                        to={`/surat-masuk/daftar-surat/detail-non-sekretariat/non-sekretariat/${list.id}`}
                      >
                        {list.no_surat}{" "}
                      </Link>
                    </td>
                    <td>{list.dari}</td>
                    <td>{list.hal}</td>
                    <td>
                      <span className="badge badge-success">
                        {list.status.text}
                      </span>
                    </td>
                    <td>{moment(list.tanggal).format("LL")}</td>
                    <td>
                      <span style={{ cursor: "pointer" }} className="fa fa-trash" onClick={() => setModal((e) => ({ ...e, modal: true, id: list.id }))}></span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

export default DaftarSuratNonSekretariat;
