import React, { Fragment, useCallback, useEffect, useState } from "react";
import Paginate from "components/footers/paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  decrement,
  increment,
  setFirstPage,
  setLastPage,
} from "utils/pagination";
import { getListMethod } from "redux/SuratReducers/ListSuratMasuk/ListSuratAction";
import Loading from "components/body/Loading";
import ModalComponent from "./modals";
import SelectSatuanKerja from "components/body/selects/selectSatuanKerja";
import SearchInput from "components/body/inputs/SearchInput";

const Content = () => {
  const dispatch = useDispatch();
  const { GeneralReducers, ListSuratReducers } = useSelector((state) => state);
  const [isOpen, setOpen] = useState(null);
  const [selectedSatuanKerja, selectSatuanKerja] = useState(0)
  const [unitKerja, setUnitKerja] = useState({
    action: "add",
    status: true,
    unit_kerja_parent: null,
    // status: true,
    posisi_unit_kerja_setelah: ""
  });
  const { loading } = GeneralReducers;
  const { items, meta } = ListSuratReducers.listMasterUnitKerja;

  const getList = (current_page = 1) => {
    dispatch(getListMethod(`pegawai-egs/api/satuan-kerja`, "listMasterSatuanKerja", current_page));
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja`, "listMasterUnitKerja", current_page, selectedSatuanKerja));

  };

  const changeFilterList = (e) => {
    dispatch(getListMethod(`pegawai-egs/api/unit-kerja`, "listMasterUnitKerja", 1, e.value));
    selectSatuanKerja(e.value)
  }

  const resetModal = () => {
    setUnitKerja({
      action: "add",
      unit_kerja_parent: null,
      status: true,
      // posisi_unit_kerja_setelah: ""
    })
    setOpen(!isOpen);
  }

  const editUnitKerja = useCallback((item) => {
    setOpen(true);
    setUnitKerja({
      action: "edit",
      // ...unitKerja,
      satuan_kerja_id: item.SatuanKerjaId,
      SatuanKerja: item.SatuanKerja,
      // posisi_unit_kerja_setelah: "",
      status: true,
      ...item
    })
  }, []);
  const editChildUnitKerja = useCallback((item, parent) => {
    setOpen(true);
    setUnitKerja({
      action: "editChild",
      // ...unitKerja,
      unit_kerja_parent: parent.id,
      parentName: parent.name,
      name: item.name,
      satuan_kerja_id: item.SatuanKerjaId,
      SatuanKerja: item.SatuanKerja,
      posisi_unit_kerja_setelah: "",
      status: true,
      ...item
    })
  }, []);

  const addChildUnitKerja = useCallback((item) => {
    setUnitKerja({
      action: "addChild",
      unit_kerja_parent: item.id,
      parentName: item.name,
      satuan_kerja_id: item.SatuanKerjaId,
      SatuanKerja: item.SatuanKerja,
      // posisi_unit_kerja_setelah: "",
      status: true,
      // ...unitKerja
    })
    setOpen(true);

  }, []);

  useEffect(getList, []);

  // console.log("itemssss", items);
  return (
    <Fragment>
      <ModalComponent
        isOpen={isOpen}
        resetModal={resetModal}
        unitKerja={unitKerja}
        getList={getList}
        setUnitKerja={setUnitKerja}
      />
      <div className="content-title row">
        <div className="col-md-12 col-sm-12 mb-2">
          <div className="row">
            <div className="col-md-6">
              <a
                onClick={() => setOpen(true)}
                href="# "
                className="btn btn-primary"
              >
                <i className="fa fa-plus"></i> Tambah Unit Kerja
              </a>
            </div>
            <div className="col-md-6">
              <SearchInput getList={getList} />
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12">
          <SelectSatuanKerja
            placeholder="Pilih SKPD"
            className="m-b-5"
            list="listMasterSatuanKerja"
            sourceReducer="ListSuratReducers"
            // defaultValue={{ value: pegawai.unit_kerja_id, label: pegawai?.unitKerja?.name }}
            onChange={changeFilterList}
          />
        </div>
      </div>
      <div className="content-body">
        <Loading isLoading={loading} />
        <table className="table table-striped table-list">
          <thead>
            <tr>
              <td>Satuan Kerja</td>
              <td>Unit Kerja</td>
              <td>Kode</td>
              <td>Opsi</td>
            </tr>
          </thead>
          <tbody>
            {items && items.map((item, i) => {
              return (
                <Fragment key={i}>
                  <tr>
                    <td>{item.SatuanKerja?.name}</td>
                    <td>
                      {item.childs.length > 0 ?
                        <i className="fa-chevron-down fa mr-1"></i> :
                        <i className="fa-chevron-right fa mt-1 mr-1"></i>
                      }
                      {item?.name}
                    </td>
                    <td>{item.code}</td>
                    <td>
                      <button className="btn btn-sm btn-primary mr-1" onClick={() => addChildUnitKerja(item)}>
                        <i className="fa fa-plus-circle"></i>
                      </button>
                      <button className="btn btn-sm btn-primary" onClick={() => editUnitKerja(item)}>
                        <i className="fa fa-pencil"></i>
                      </button>
                    </td>
                  </tr>
                  <ContentTable data={item} pl={4} addChildUnitKerja={addChildUnitKerja} editChildUnitKerja={editChildUnitKerja} />
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      {meta && (
        <Paginate
          increment={() => increment(meta.current_page, getList)}
          meta={meta}
          decrement={() => decrement(meta.current_page, getList)}
          setLastPage={() => setLastPage(meta.last_page, getList)}
          setFirstPage={() => setFirstPage(meta.from, getList)}
        />
      )}
    </Fragment>
  );
};

const ContentTable = ({ data, pl, addChildUnitKerja, editChildUnitKerja }) => {
  // console.log("dataaaa", data);

  if (!data.childs) {
    return <></>
  }

  return data.childs.map((item, i) => {
    return (
      <Fragment key={i}>
        <tr>
          <td></td>
          <td style={{
            "display": "flex",
            "flexDirection": "row",
            "paddingLeft": `${pl}%`
          }}>
            {item.childs.length > 0 ?
              <i className="fa-chevron-down fa mr-1"></i> :
              <i className="fa-chevron-right fa mt-1 mr-1"></i>
            }
            <span> {item.name}</span>
          </td>
          <td>{item.code}</td>
          <td width="100px">
            <button className="btn btn-sm btn-primary mr-1" onClick={() => addChildUnitKerja(item)}>
              <i className="fa fa-plus-circle"></i>
            </button>
            <button className="btn btn-sm btn-primary" onClick={() => editChildUnitKerja(item, { name: data.name, id: data.id })}>
              <i className="fa fa-pencil"></i>
            </button>
          </td>
        </tr>
        <ContentTable key={i} data={item} pl={pl + 3} addChildUnitKerja={addChildUnitKerja} editChildUnitKerja={editChildUnitKerja} />
      </Fragment>
    )
  })



}

export default Content;
