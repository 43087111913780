import React, { Fragment } from "react";
import { postFileRequest } from "config/axios";
import { useDispatch, useSelector } from "react-redux";
import { checkError, setLoading } from "redux/GeneralReducers/GeneralActions";
import { set_value } from "redux/SuratReducers/BuatSurat/BuatSuratActions";
import PrevBtn from "components/body/buttons/prevBtn";
import NextBtn from "components/body/buttons/nextBtn";

const Lampiran = (props) => {
  const dispatch = useDispatch();
  const { GeneralReducers, BuatSuratReducers } = useSelector((state) => state);
  const { lampiran } = BuatSuratReducers;

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const uploadFileDropped = (data) => {
    dispatch(setLoading(true));
    const formData = new FormData();

    formData.append("file", data);
    return new Promise((res) => {
      postFileRequest(`api/master/store-file`, formData)
        .then((respon) => {
          res(respon.data.data);
        })
        .catch((err) => dispatch(checkError(err)));
    });
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    let promises = [];
    for (let i = 0; i < files.length; i++) {
      promises = [...promises, uploadFileDropped(files[i])];
    }

    Promise.all(promises)
      .then((res) => {
        dispatch(
          set_value({
            value: [...lampiran, ...res],
            name: "lampiran",
          })
        );
        dispatch(setLoading(false));
      })
      .catch((err) => dispatch(checkError(err)));
  };

  const onClickFile = async (e) => {
    e.preventDefault();
    const files = e.target.files;

    const result = await uploadFileDropped(files[0]);

    if (result) {
      dispatch(
        set_value({
          value: [...lampiran, result],
          name: "lampiran",
        })
      );
      dispatch(setLoading(false));
    }
  };

  const delAttacment = (id) => {
    const newLampiran = lampiran.filter((list) => list._id !== id);
    dispatch(
      set_value({
        value: [...newLampiran],
        name: "lampiran",
      })
    );
  };

  return (
    <Fragment>
      <div className="row reset-margin-row">
        <div className="col-md-6 content-modal">
          <div className="container-fluid">
            <div className="form-group">
              {/* {GeneralReducers.status === "fail" && (
                <div className="alert alert-danger">{GeneralReducers.msg}</div>
              )} */}

              <div className="content-lampiran">
                <div className="container-lampiran">
                  <div
                    className="drop-container-lampiran"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                  >
                    <input
                      type="file"
                      id="file"
                      onChange={onClickFile}
                      style={{ display: "none" }}
                    />
                    <div className="drop-message-lampiran">
                      <div className="upload-icon-lampiran"></div>
                      Drag & Drop files here or click{" "}
                      <label htmlFor="file" className="pointer-mouse">
                        <b> here </b>
                      </label>
                      <p>File yang bisa di proses maksimal 100MB</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 content-modal">
          Files:{" "}
          <div className="badge badge-success">{`${lampiran.length} terupload`}</div>
          <div className="container-fluid m-t-10">
            {GeneralReducers.status === "success-lampiran" && (
              <div className="alert alert-success">{GeneralReducers.msg}</div>
            )}

            {lampiran &&
              lampiran.map((list, i) => {
                return (
                  <div key={i} className="font-12">
                    <a href="# " onClick={() => delAttacment(list._id)}>
                      <i className="fa fa-trash red"></i>
                    </a>{" "}
                    <i className="fa fa-check"></i> {list.file_name} {" "} ({list.file_size})
                  </div>
                );
              })}
          </div>
          <hr />
        </div>
      </div>
      <div className="col-md-12 flex-space-between">
        <PrevBtn to="isi-surat" />
        <NextBtn to="info-jadwal" />
      </div>
    </Fragment>
  );
};

export default Lampiran;
