import { messaging } from "../firebase";
import { set_token_fcm } from "redux/loginRedux/LoginActios";

const { store } = require("redux/rootRedux/Store");

const isSupported = () => {
  return "Notification" in window && "serviceWorker" in navigator && "PushManager" in window
}

const getTokenFcm = () => {
  const hasPermission = isSupported()
  if (hasPermission) {
    Notification.requestPermission();
    if (messaging) {
      messaging
        .getToken()
        .then((token) => {
          store.dispatch(set_token_fcm(token));
        })
        .catch((err) => console.log("errorr get token FCM", err));
    }
  }
};

export {
  getTokenFcm
}