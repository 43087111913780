import React, { Component } from "react";
// import Navbar from "components/header/navbar";
// import Sidebar from "components/sidebar/sidebar";
// import SubNavbarWrapper from "components/header/subNavbarWrapper";
// import Footer from "components/footers/footer";
// import ContentWrapper from "components/body/contentWrapper";
import Layout from 'layout';
import Content from "./cards/content";
// import SubNavbar from "./cards/subNavbar";

export default class MasterKlasifikasiPage extends Component {
  render() {
    return (
      // <Fragment>
      //   <div className="background-dark"></div>
      //   <Navbar />
      //   <Sidebar />
      //   <SubNavbarWrapper>
      //     <SubNavbar />
      //   </SubNavbarWrapper>
      //   <ContentWrapper>
      //     <Content />
      //   </ContentWrapper>
      //   <Footer />
      // </Fragment>
      <Layout subnavbar={{ type: "standart_header", title: "Master Klasifikasi" }}>
        <Content />
      </Layout>
    );
  }
}
